import { LogoutIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import NavItem from 'components/NavItem';
import PropTypes from 'prop-types';
import React from 'react';

import MindoveLogoWhite from '../../assets/icons/logotipo_white_transparent.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBarMobile({ navigation, userStatus, setSidebarOpen, setAuth }) {
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow pt-5 bg-primary overflow-y-auto shadow-lg">
        <div className="flex items-center flex-shrink-0 px-4">
          <img alt="Mindove" src={MindoveLogoWhite} />
        </div>
        <div className="mt-5 flex-1 flex flex-col">
          <nav className="flex-1 px-2 pb-4 space-y-1">
            {navigation.map((item) => {
              if (item.noShow.includes(userStatus)) return null;
              return (
                <NavItem
                  key={item.name}
                  name={item.name}
                  href={item.href}
                  classNames={classNames}
                  Icon={item.icon}
                  IconClassName={
                    'mr-3 flex-shrink-0 h-6 w-6 text-white hover:text-primary focus:text-primary'
                  }
                  onClick={() => setSidebarOpen(false)}
                />
              );
            })}
          </nav>
          <div className="my-2 p-2">
            <Button
              className="border border-1 rounded-md border-white text-white"
              icon={<LogoutIcon className="h-6 w-6 text-white p-4" />}
              onClick={() => setAuth()}
            >
              Cerrar session
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

NavBarMobile.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
  navigation: PropTypes.array.isRequired,
  userStatus: PropTypes.number.isRequired,
  setAuth: PropTypes.func.isRequired,
};

NavBarMobile.defaultProps = {
  navigation: [],
};
