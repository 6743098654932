import { yupResolver } from '@hookform/resolvers/yup';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Content from 'components/Content';
import Title from 'components/Title';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ContractSchema } from 'schemas/proposal';
import { get as getContract } from 'services/contract';
import { authState } from 'state/auth';
import { requestState } from 'state/request';

export default function Contract() {
  // Global state
  const [auth] = useRecoilState(authState);
  const [request, setRequest] = useRecoilState(requestState);

  // Local state
  const [section, setSection] = useState(null);

  const handleGetContractQuerySuccess = (data) => {
    setSection(data.sections[0]);
  };

  // RQ
  const getContractQuery = useQuery(['getContract', auth.token], () => getContract(auth.token), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: handleGetContractQuerySuccess,
  });

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      accept: false,
    },
    resolver: yupResolver(ContractSchema),
  });

  const onSubmit = () => {
    const sectionsLength = getContractQuery.data.sections.length - 1;
    const currentSection = section.order - 1;
    if (currentSection === sectionsLength) {
      const newRequestState = {
        contractCheck: true,
        contractID: getContractQuery.data.id,
      };
      setRequest({
        ...request,
        ...newRequestState,
      });
      navigate('../paymentMethod');
    }

    if (currentSection < sectionsLength) {
      setSection(getContractQuery.data.sections[currentSection + 1]);
      reset();
      window.scrollTo(0, 0);
    }
  };

  if (getContractQuery.isError) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="max-w-xl flex-1">
          <div className="w-full flex flex-col">
            <Title className="mt-4 text-lg leading-6 font-semibold text-red-500">Error</Title>
            <Content className="mt-2 text-secondary">{getContractQuery.error.message}</Content>

            <Button
              onClick={() => navigate('/app')}
              boxShadow={true}
              className="mt-4 text-white bg-secondary text-base leading-6 font-medium border border-gray-300 rounded-md"
            >
              Vamos al Home
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (getContractQuery.isSuccess) {
    const patientName = `${auth?.user?.first_name} ${auth?.user?.last_name}`;
    const psychologistName = `${request?.psychologist?.firstName} ${request?.psychologist?.lastName}`;
    const now = moment().format('DD [de] MMMM [del] YYYY');

    let content = section?.content || '';
    content = content.replaceAll('[PATIENT]', `**${patientName}**`);
    content = content.replaceAll('[DOVER]', `**${psychologistName}**`);
    content = content.replaceAll('[DATE]', `**${now}**`);
    // content = content.replaceAll('[PERIODICIDAD]', crontabMessage);
    // content = content.replaceAll('[DIAS]', daysMessage);

    return (
      <>
        <div className="col-span-1 md:col-start-2 px-4 w-20 mb-4">
          <BackButton onClick={() => navigate(-1)} />
        </div>
        <div className="sm:col-span-1 md:col-span-6 md:col-start-2 p-4">
          <Title className="text-title text-xl">{getContractQuery.data.title}</Title>

          <Content className="prose mt-4 text-content">
            <ReactMarkdown>{content}</ReactMarkdown>
          </Content>
          <br />
          <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
            <div className="px-1">
              <Controller
                name="accept"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    id="accept"
                    text={
                      <p className="font-normal text-gray-500">
                        He leído y comprendido lo anterior,{' '}
                        <span className="cursor-pointer font-bold text-gray-800">
                          acepto Términos y Condiciones.
                        </span>
                      </p>
                    }
                    checked={value}
                    onChange={onChange}
                    error={errors.accept?.message}
                  />
                )}
              />
            </div>

            <Button type="submit" boxShadow={true} className="btn-primary">
              Siguiente
            </Button>
          </form>
        </div>
      </>
    );
  }
  return null;
}
