import React from 'react';
import { useRecoilState } from 'recoil';
import { accompanimentState } from 'state/accompaniment';

import { CometChatUI } from '../../../CometChatWorkspace/src';

export default function ICChat() {
  // Global state
  const [accompaniment] = useRecoilState(accompanimentState);

  return (
    <div className="w-full h-[32rem] px-4">
      {accompaniment?.psychologist ? (
        <CometChatUI chatWithUser={accompaniment?.psychologist?.cometchat_id} />
      ) : (
        <div className="grid grid-cols-3">
          <div className="col-span-1 col-start-2 flex justify-center mt-8">
            <p className="text-content">Algo salio mal.</p>
          </div>
        </div>
      )}
    </div>
  );
}
