import {
  GlobeAltIcon,
  LocationMarkerIcon,
  MailIcon,
  PencilIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import Button from 'components/Button';
import Content from 'components/Content';
import Header from 'components/Header';
import ProfileCard from 'components/ProfileCard';
import Subtitle from 'components/Subtitle';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

export default function ProfileDetail() {
  // Global state
  // const token = useRecoilValue(getToken);x

  // Context
  const { user } = useOutletContext();
  const { address } = user;

  // RRDs
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 gap-4 my-4 md:my-16">
      <Header title="Editar Perfil" description="Aquí podrás editar tu perfil">
        <div className="col-span-1 mt-4 md:mt-0">
          <Button
            className="btn-secondary"
            onClick={() => navigate('update')}
            icon={<PencilIcon className="h-6 w-6" />}
          />
        </div>
      </Header>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-8  mt-8">
        <div className="grid gap-y-8">
          <ProfileCard
            image={user?.photo_URL}
            title={`${user?.first_name} ${user?.last_name}`}
            // TODO: ADD CONTENT
            // content="About me."
            navigate={navigate}
            redirectTo="update"
          />
          <div>
            <Subtitle className="text-subtitle">Sobre mí</Subtitle>
            <Content className="text-contet text-gray-500">
              <p>{user.biography}</p>
            </Content>
          </div>
        </div>
        <div>
          <div className="mb-8">
            <Subtitle className="text-subtitle">Contacto</Subtitle>
            <div className="flex flex-col gap-y-2 mt-4">
              <div className="inline-flex">
                <MailIcon className="h-6 w-6 text-secondary font-bold mr-4" />
                <Content className="text-content text-gray-500">
                  <p>{user.email}</p>
                </Content>
              </div>
              <div className="inline-flex">
                <PhoneIcon className="h-6 w-6 text-secondary font-bold mr-4" />
                <Content className="text-content text-gray-500">
                  <p>{user.cell_phone}</p>
                </Content>
              </div>
            </div>
          </div>
          <div>
            <Subtitle className="text-subtitle">Dirección</Subtitle>
            <div className="flex flex-col gap-y-2 mt-4">
              <div className="inline-flex">
                <GlobeAltIcon className="h-6 w-6 text-secondary font-bold mr-4" />
                <Content className="text-content text-gray-500">
                  <p>{address?.timezone.name}</p>
                </Content>
              </div>
              <div className="inline-flex">
                <LocationMarkerIcon className="h-6 w-6 text-secondary font-bold mr-4" />
                <Content className="text-content text-gray-500">
                  <p>
                    {address?.state?.name}, {address?.country?.name}
                  </p>
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
