import { CheckIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Content from 'components/Content';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listPaymentMethods } from 'services/payments';
import { getTokenSelector } from 'state/auth';
import { requestState } from 'state/request';

export default function PaymentMethod() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [request, setRequest] = useRecoilState(requestState);

  // RRD
  const navigate = useNavigate();

  // RHF
  const listPaymentMethodsQuery = useQuery(['listPaymentMethods', token], () =>
    listPaymentMethods(token),
  );

  const buildPaymentMethod = (paymentMethod, index) => {
    // const cardType = getCardType(paymentMethod.toString());
    return (
      <div key={index} className="grid shadow-md hover:shadow-lg rounded-md border-box">
        <div className="p-4">
          <div>
            <Content className="text-tertiary">{paymentMethod.card_number}</Content>
          </div>
          <div>
            <Content>{paymentMethod.card_holder_name}</Content>
          </div>
        </div>
        <div className="bg-tertiary mt-2 p-2 flex items-center rounded-md">
          <Button
            className="w-full text-white cursor-pointer"
            onClick={() => {
              const newRequestState = {
                paymentMethod: paymentMethod.id,
              };
              setRequest({
                ...request,
                ...newRequestState,
              });
              navigate('../pay');
            }}
            icon={<CheckIcon className="h-6 w-6 text-white mr-4" />}
          >
            Seleccionar
          </Button>
        </div>
      </div>
    );
  };

  const addPaymentMethodHandler = () => {
    const paymentMethodHost = new URL(process.env.REACT_APP_PAYMENT_METHOD_HOST);
    paymentMethodHost.searchParams.append('at', token);
    paymentMethodHost.searchParams.append('o', window.location.href);
    paymentMethodHost.searchParams.append('r', window.location.href);
    window.location.replace(paymentMethodHost);
  };

  return (
    <>
      <Title className="mt-4 text-title">Selecciona un Método de Pago</Title>
      <Content className="mt-2 text-content">
        Para continuar tu suscripción al Proceso de Acompañamiento Psicoespiritual selecciona un
        método de págo.
      </Content>
      <br />
      {listPaymentMethodsQuery.isLoading ? (
        <div className="flex justify-center items-center">
          <Loader className="h-10 w-10 text-secondary" />
        </div>
      ) : (
        <div className="grid grid-cols-1">
          <div className="grid grid-cols-1 md:grid-cols-3 mb-4">
            <div className="col-start-3">
              <Button onClick={addPaymentMethodHandler} className="btn-primary">
                Agregar método de pago
              </Button>
            </div>
          </div>
          {listPaymentMethodsQuery.isSuccess && listPaymentMethodsQuery.data.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {listPaymentMethodsQuery.data.map(buildPaymentMethod)}
            </div>
          ) : (
            <p className="leading-6 text-md text-gray-500">No tienes metodos de pago</p>
          )}
          <br />
        </div>
      )}
    </>
  );
}
