import Button from 'components/Button';
import Input from 'components/Input';
import ProfilePictureInput from 'components/ProfilePictureInput';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import React from 'react';

export default function ProfileUpdateForm({
  handleSubmit,
  onSubmit,
  setFile,
  disabled,
  photoURL,
  register,
  countries,
  states,
  timezones,
  errors,
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)} method="POST">
      <div className="space-y-12">
        <div className=" grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2">
          <div className="">
            <ProfilePictureInput
              id="preview"
              setValue={setFile}
              disabled={disabled}
              photoURL={photoURL}
            />
          </div>
        </div>
      </div>

      <div className="my-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Información Personal</h2>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Input
              required
              disabled={disabled}
              id="firstName"
              type="text"
              register={register}
              registerConfig={{ required: true }}
              withLabel
              textLabel="Nombre"
              error={errors.firstName?.message}
            />
          </div>

          <div className="sm:col-span-3">
            <Input
              required
              disabled={disabled}
              id="lastName"
              type="text"
              register={register}
              registerConfig={{ required: true }}
              withLabel
              textLabel="Apellidos"
              error={errors.lastName?.message}
            />
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Select
              required
              disabled={disabled}
              id="address.country"
              register={register}
              registerConfig={{ required: true }}
              textLabel="País"
              error={errors.address?.country?.message}
              selectOptions={countries}
              withDefaultOption
            />
          </div>

          <div className="sm:col-span-3">
            <Select
              required
              disabled={disabled || !states.length}
              id="address.state"
              register={register}
              registerConfig={{ required: true }}
              textLabel="Estado/Provincia"
              error={errors.address?.state?.message}
              selectOptions={states}
              withDefaultOption
            />
          </div>

          <div className="sm:col-span-3">
            <Select
              required
              disabled={disabled || !timezones.length}
              id="address.timezone"
              register={register}
              registerConfig={{ required: true }}
              textLabel="Zona Horaria"
              error={errors.address?.timezone?.message}
              selectOptions={timezones}
              withDefaultOption
            />
          </div>

          <div className="sm:col-span-3">
            <Input
              required
              disabled={disabled}
              id="address.zipCode"
              type="text"
              register={register}
              registerConfig={{ required: true }}
              withLabel
              textLabel="Código Postal"
              error={errors.zipCode?.message}
            />
          </div>
        </div>

        <div className="my-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Información de Contacto
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Input
                required
                disabled={true}
                id="email"
                type="email"
                register={register}
                registerConfig={{ required: true }}
                withLabel
                textLabel="Correo Electrónico"
                error={errors.email?.message}
              />
            </div>

            <div className="sm:col-span-3">
              <Input
                required
                disabled={disabled}
                id="cellPhone"
                type="text"
                register={register}
                registerConfig={{ required: true }}
                withLabel
                textLabel="Teléfono Móvil"
                error={errors.cellPhone?.message}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-4">
        <Button
          disabled={disabled}
          type="submit"
          className="col-span-1 md:col-span-2 md:col-start-4 text-center text-sm rounded-md btn-primary"
        >
          {disabled ? 'Guardando' : 'Guardar'}
        </Button>
      </div>
    </form>
  );
}

ProfileUpdateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  photoURL: PropTypes.string,
  register: PropTypes.func.isRequired,
  countries: PropTypes.array,
  states: PropTypes.array,
  timezones: PropTypes.array,
  watchCountry: PropTypes.any,
  errors: PropTypes.object,
};

ProfileUpdateForm.defaultProps = {
  countries: [],
  states: [],
  timezones: [],
  photoURL: '',
};
