import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const authState = atom({
  key: 'auth',
  default: {
    user: {},
    token: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const getTokenSelector = selector({
  key: 'getToken',
  get: ({ get }) => get(authState)?.token,
});

export const getUserSelector = selector({
  key: 'getUser',
  get: ({ get }) => get(authState)?.user,
});

export const getStatusSelector = selector({
  key: 'getStatus',
  get: ({ get }) => get(authState)?.user?.status,
});

export const isProfileCompletedSelector = selector({
  key: 'isProfileCompleted',
  get: ({ get }) => get(authState)?.user?.profile_completed,
});

export const isNewSelector = selector({
  key: 'isNew',
  get: ({ get }) => get(authState)?.user?.isNew,
});
