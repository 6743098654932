import PropTypes from 'prop-types';
import React from 'react';

export default function ProgressBar({ children, percentageBar, timeCompleted }) {
  /**
   * This is to know the percentage of the progress bar
   * It will change depending on the questions
   */

  return (
    <div>
      {percentageBar !== 100 ? (
        <div className="flex flex-col items-center">
          <span className="font-default text-xs text-gray-600 my-2 ">{children}</span>
          <div className="bg-primary w-full h-5 rounded">
            <div
              style={{ width: `${String(percentageBar)}%` }}
              className={`bg-primary h-full rounded`}
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <span className="font-default text-xs text-gray-600">{children}</span>
          <span className="font-default text-xs text-gray-600">{timeCompleted}</span>
        </div>
      )}
    </div>
  );
}

ProgressBar.propTypes = {
  children: PropTypes.string.isRequired,
  percentageBar: PropTypes.number.isRequired,
  timeCompleted: PropTypes.string,
};

ProgressBar.defaultProps = {
  percentageBar: 0,
};
