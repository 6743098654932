import { StarIcon, TicketIcon } from '@heroicons/react/solid';

import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from '../../../utils/statuses';

// const cancelAction = {
//   title: 'Cancelar',
//   description: 'Some description 1',
//   icon: <XCircleIcon className="h-8 w-8 text-red-400" />,
//   redirectTo: 'cancel',
// };
// const rescheduleAction = {
//   title: 'Reagendar',
//   description: 'Some description 2',
//   icon: <ClockIcon className="h-8 w-8 text-secondary" />,
//   redirectTo: 'reschedule',
// };
// const payAction = {
//   title: 'Pagar',
//   description: 'Some description 3',
//   icon: <CreditCardIcon className="h-8 w-8 text-green-500" />,
//   redirectTo: 'pay',
// };

const reviewAction = {
  title: 'Calificar',
  description: 'Some description 4',
  icon: <StarIcon className="h-8 w-8 text-yellow-500" />,
  redirectTo: 'review',
};

const ticketsAction = {
  title: 'Ver Solicitudes',
  description: 'Some description 5',
  icon: <TicketIcon className="h-8 w-8 text-sprimary" />,
  redirectTo: 'tickets',
};

const actionsByStatus = {
  [SESSION_CREATED]: new Set([]),
  [SESSION_SCHEDULED]: new Set([ticketsAction]),
  [SESSION_PAYED]: new Set([ticketsAction]),
  [SESSION_UNPAYED]: new Set([ticketsAction]),
  [SESSION_CANCELED]: new Set([ticketsAction]),
  [SESSION_IN_PROGRESS]: new Set([ticketsAction]),
  [SESSION_TIME_COMPLETED]: new Set([ticketsAction]),
  [SESSION_FINALIZED]: new Set([ticketsAction]),
};

export const getActionsByStatus = (status, reviewed) => {
  const actions = actionsByStatus[String(status)];
  if ([SESSION_TIME_COMPLETED, SESSION_FINALIZED].includes(status) && !reviewed) {
    actions.add(reviewAction);
  }
  return actions;
};
