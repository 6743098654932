import PropTypes from 'prop-types';
import React from 'react';

export default function ImageCard({ title, Icon, iconAnimation, className, children, callback }) {
  return (
    <div
      role="button"
      className={`flex justify-between items-center w-full p-6 z-10 rounded-2xl cursor-pointer hover:shadow-xl ${className}`}
      onClick={callback}
      onKeyDown={callback}
      tabIndex={0}
    >
      <div className={`flex flex-col ${Icon ? 'w-3/4' : 'w-full'}`}>
        <span className="font-default font-semibold text-xl text-white leading-7 mb-2">
          {title}
        </span>
        <span className="font-default font-light text-md text-white leading-5">{children}</span>
      </div>
      {Icon && (
        <div className="rounded-full z-20">
          <Icon className={`${iconAnimation} h-10 w-10 text-white`} />
        </div>
      )}
    </div>
  );
}

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  imgSrc: PropTypes.any,
  Icon: PropTypes.any,
  iconAnimation: PropTypes.string,
  callback: PropTypes.func,
};

ImageCard.defaultProps = {
  className: 'bg-primary',
  iconAnimation: '',
};
