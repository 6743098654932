import React from 'react';

export default function Frame02() {
  return (
    <svg
      width="166"
      height="122"
      viewBox="0 0 166 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1744_34125)">
        <path
          d="M153.786 121.307V107.532C153.786 107.532 159.154 117.297 153.786 121.307Z"
          fill="#F1F1F1"
        />
        <path
          d="M154.117 121.295L143.969 111.98C143.969 111.98 154.794 114.631 154.117 121.295Z"
          fill="#F1F1F1"
        />
        <path
          d="M110.281 84.926C110.261 84.6994 110.285 84.471 110.353 84.2539C110.421 84.0368 110.531 83.8352 110.677 83.6606C110.823 83.4861 111.002 83.342 111.204 83.2366C111.405 83.1313 111.626 83.0667 111.852 83.0466C111.954 83.0371 112.056 83.0371 112.157 83.0466L113.989 79.5315L116.392 80.1218L113.739 85.0421C113.676 85.4647 113.459 85.8491 113.13 86.1221C112.802 86.3951 112.384 86.5377 111.957 86.5226C111.53 86.5076 111.123 86.3359 110.815 86.0405C110.506 85.745 110.317 85.3462 110.283 84.9203L110.281 84.926Z"
          fill="#EFD1BA"
        />
        <path d="M121.2 119.112H119.172L118.207 111.287H121.2V119.112Z" fill="#EFD1BA" />
        <path
          d="M121.717 121.077H115.176V120.995C115.176 120.66 115.242 120.329 115.37 120.02C115.497 119.711 115.685 119.43 115.921 119.194C116.158 118.957 116.439 118.77 116.748 118.642C117.057 118.514 117.388 118.448 117.722 118.449H121.721L121.717 121.077Z"
          fill="#2F2E41"
        />
        <path
          d="M137.514 117.596L135.557 118.129L132.567 110.834L135.456 110.048L137.514 117.596Z"
          fill="#EFD1BA"
        />
        <path
          d="M138.532 119.357L132.221 121.071L132.198 120.991C132.022 120.34 132.11 119.646 132.445 119.06C132.779 118.475 133.332 118.046 133.983 117.868L137.839 116.817L138.532 119.357Z"
          fill="#2F2E41"
        />
        <path
          d="M117.496 85.3124L116.485 100.679L118.658 117.917L122.218 116.83L122.369 101.427L125.217 92.5463L133.649 116.268L137.509 114.844L134.323 99.4413L132.526 84.9753L117.496 85.3124Z"
          fill="#2F2E41"
        />
        <path
          d="M127.333 58.3303L121.339 59.2196L119.179 61.3522L118.507 69.026L118.924 75.8239L117.188 87.8202C117.188 87.8202 121.554 85.3962 124.937 88.4314C128.321 91.4667 133.057 88.9665 133.154 86.9481L127.333 58.3303Z"
          fill="#CBCBCB"
        />
        <path
          d="M124.29 67.9578V67.9483L127.268 58.237H127.306C127.518 58.2237 132.512 57.9305 133.628 60.3945V60.4078L133.289 70.4999L133.756 86.3045L124.52 88.3039L124.454 88.3191L124.29 67.9578Z"
          fill="#2F2E41"
        />
        <path
          d="M120.988 67.2855L121.534 59.0557C117.65 59.2975 117.707 62.0528 117.713 62.1728L117.669 74.4832L116.892 87.6524L119.722 87.441L120.988 67.2855Z"
          fill="#2F2E41"
        />
        <path
          d="M112.227 82.26L115.675 74.2625L118.084 69.8411L119.341 77.8176L114.977 84.3546L112.227 82.26Z"
          fill="#2F2E41"
        />
        <path
          d="M126.484 79.3143C126.537 79.0658 126.644 78.8319 126.798 78.6293C126.951 78.4266 127.147 78.2601 127.372 78.1415C127.597 78.023 127.845 77.9552 128.099 77.943C128.353 77.9308 128.606 77.9744 128.841 78.0709L131.623 75.2566L133.746 76.5305L129.751 80.4511C129.565 80.8357 129.244 81.1383 128.848 81.3013C128.453 81.4644 128.012 81.4766 127.609 81.3355C127.205 81.1944 126.868 80.91 126.66 80.5363C126.453 80.1625 126.39 79.7256 126.484 79.3086V79.3143Z"
          fill="#EFD1BA"
        />
        <path
          d="M128.824 77.0444L134.498 70.8197L130.745 65.4595L131.126 62.8374L133.339 60.103L133.382 60.1582C133.618 60.4648 139.163 67.6683 139.323 70.3951C139.483 73.1218 131.516 80.0587 131.173 80.352L131.126 80.3919L128.824 77.0444Z"
          fill="#2F2E41"
        />
        <path
          d="M123.875 57.6564C126.102 57.6564 127.908 55.8507 127.908 53.6233C127.908 51.396 126.102 49.5903 123.875 49.5903C121.647 49.5903 119.842 51.396 119.842 53.6233C119.842 55.8507 121.647 57.6564 123.875 57.6564Z"
          fill="#EFD1BA"
        />
        <path
          d="M127.668 50.3994L127.859 50.0052L126.88 49.5082C126.88 49.5082 125.795 47.7431 123.833 48.2401C121.872 48.7371 120.986 49.0322 120.986 49.0322L120.006 49.5273L120.497 50.0167L119.614 50.3137L120.204 50.6069L119.511 51.0087L119.892 53.0329C119.892 53.0329 120.505 51.4981 121.685 52.0808C122.866 52.6635 125.025 51.778 125.025 51.778L126.895 55.4112C126.895 55.4112 127.276 54.1373 127.971 54.4762C127.971 54.4781 129.734 51.6714 127.668 50.3994Z"
          fill="#2F2E41"
        />
        <path
          d="M82.0308 73.2343L20.6975 90.149L0.5 16.9147L61.8333 0L82.0308 73.2343Z"
          fill="white"
        />
        <path
          d="M82.0308 73.2343L20.6975 90.149L0.5 16.9147L61.8333 0L82.0308 73.2343ZM21.4782 88.7685L80.6483 72.4498L61.0525 1.38052L1.88052 17.7088L21.4782 88.7685Z"
          fill="#F1F1F1"
        />
        <path
          d="M48.485 32.8068L33.1089 37.0474C32.8923 37.107 32.6608 37.078 32.4655 36.967C32.2702 36.8559 32.1269 36.6719 32.0673 36.4552L27.8267 21.081C27.7671 20.8641 27.796 20.6324 27.907 20.4368C28.0181 20.2411 28.2022 20.0975 28.4189 20.0375L43.7951 15.8045C44.012 15.7449 44.2437 15.7738 44.4393 15.8848C44.6349 15.9959 44.7785 16.18 44.8386 16.3967L49.0791 31.771C49.1364 31.9871 49.1061 32.217 48.9949 32.4109C48.8837 32.6049 48.7005 32.7471 48.485 32.8068ZM28.5103 20.3745C28.4459 20.3924 28.3856 20.4227 28.333 20.4639C28.2803 20.505 28.2362 20.5561 28.2033 20.6143C28.1704 20.6725 28.1493 20.7366 28.1413 20.803C28.1332 20.8693 28.1382 20.9366 28.1562 21.001L32.3948 36.3695C32.4313 36.4994 32.5176 36.6095 32.635 36.6758C32.7524 36.7422 32.8913 36.7594 33.0213 36.7237L48.3955 32.485C48.5254 32.4486 48.6357 32.3623 48.7024 32.245C48.769 32.1277 48.7867 31.9888 48.7516 31.8586L44.511 16.4805C44.475 16.3503 44.3888 16.2398 44.2714 16.173C44.154 16.1063 44.0149 16.0888 43.8846 16.1244L28.5103 20.3745Z"
          fill="#E5E5E5"
        />
        <path
          d="M44.5887 35.9068L29.2145 40.1455C29.118 40.1724 29.0171 40.18 28.9176 40.1679C28.8182 40.1557 28.7221 40.124 28.6349 40.0747C28.5477 40.0253 28.4711 39.9593 28.4094 39.8803C28.3478 39.8012 28.3024 39.7109 28.2757 39.6143L24.0352 24.2381C24.0083 24.1415 24.0008 24.0405 24.013 23.9409C24.0253 23.8413 24.0571 23.7452 24.1067 23.658C24.1562 23.5707 24.2225 23.4941 24.3017 23.4326C24.381 23.3711 24.4715 23.3258 24.5683 23.2994L39.9426 19.0607C40.0391 19.0338 40.14 19.0262 40.2394 19.0383C40.3389 19.0505 40.435 19.0822 40.5222 19.1315C40.6094 19.1809 40.686 19.2469 40.7476 19.3259C40.8093 19.4049 40.8547 19.4953 40.8813 19.5919L45.1219 34.9681C45.1488 35.0647 45.1563 35.1657 45.144 35.2653C45.1317 35.3649 45.0999 35.461 45.0504 35.5482C45.0008 35.6355 44.9345 35.712 44.8553 35.7736C44.7761 35.8351 44.6855 35.8804 44.5887 35.9068Z"
          fill="#9EBCF6"
        />
        <path
          d="M62.4011 38.0036L19.4971 49.8374L19.9543 51.495L62.8583 39.6611L62.4011 38.0036Z"
          fill="#F1F1F1"
        />
        <path
          d="M63.593 42.3256L20.689 54.1594L21.1462 55.817L64.0502 43.9831L63.593 42.3256Z"
          fill="#F1F1F1"
        />
        <path
          d="M64.7853 46.6478L21.8813 58.4817L22.3385 60.1393L65.2425 48.3054L64.7853 46.6478Z"
          fill="#F1F1F1"
        />
        <path
          d="M66.8835 54.257L23.9795 66.0908L24.4367 67.7484L67.3407 55.9146L66.8835 54.257Z"
          fill="#F1F1F1"
        />
        <path
          d="M68.0754 58.5792L25.1714 70.4131L25.6286 72.0707L68.5326 60.2368L68.0754 58.5792Z"
          fill="#F1F1F1"
        />
        <path
          d="M69.2673 62.9013L26.3633 74.7351L26.8205 76.3927L69.7245 64.5588L69.2673 62.9013Z"
          fill="#F1F1F1"
        />
        <path d="M101.665 89.9035H38.041V13.9368H101.665V89.9035Z" fill="white" />
        <path
          d="M101.665 89.9035H38.041V13.9368H101.665V89.9035ZM39.155 88.782H100.541V15.0583H39.155V88.782Z"
          fill="#E5E5E5"
        />
        <path d="M90.0794 46.593H53.7041V48.3125H90.0794V46.593Z" fill="#9EBCF6" />
        <path d="M90.0794 51.0659H53.7041V52.7854H90.0794V51.0659Z" fill="#9EBCF6" />
        <path d="M90.0794 55.5371H53.7041V57.2566H90.0794V55.5371Z" fill="#9EBCF6" />
        <path
          d="M50.747 48.8269C51.3665 48.8269 51.8686 48.3247 51.8686 47.7053C51.8686 47.0859 51.3665 46.5837 50.747 46.5837C50.1276 46.5837 49.6255 47.0859 49.6255 47.7053C49.6255 48.3247 50.1276 48.8269 50.747 48.8269Z"
          fill="#9EBCF6"
        />
        <path d="M90.0794 27.3267H53.7041V29.0461H90.0794V27.3267Z" fill="#E5E5E5" />
        <path d="M90.0794 31.7976H53.7041V33.5171H90.0794V31.7976Z" fill="#E5E5E5" />
        <path d="M90.0794 36.2688H53.7041V37.9883H90.0794V36.2688Z" fill="#E5E5E5" />
        <path
          d="M50.747 29.3718C51.3665 29.3718 51.8686 28.8696 51.8686 28.2502C51.8686 27.6308 51.3665 27.1287 50.747 27.1287C50.1276 27.1287 49.6255 27.6308 49.6255 28.2502C49.6255 28.8696 50.1276 29.3718 50.747 29.3718Z"
          fill="#E5E5E5"
        />
        <path d="M90.0794 66.0498H53.7041V67.7693H90.0794V66.0498Z" fill="#E5E5E5" />
        <path d="M90.0794 70.5208H53.7041V72.2402H90.0794V70.5208Z" fill="#E5E5E5" />
        <path d="M90.0794 74.9917H53.7041V76.7112H90.0794V74.9917Z" fill="#E5E5E5" />
        <path
          d="M50.747 68.095C51.3665 68.095 51.8686 67.5928 51.8686 66.9734C51.8686 66.354 51.3665 65.8518 50.747 65.8518C50.1276 65.8518 49.6255 66.354 49.6255 66.9734C49.6255 67.5928 50.1276 68.095 50.747 68.095Z"
          fill="#E5E5E5"
        />
        <path
          d="M98.694 96.4461C105.876 96.4461 111.698 90.6242 111.698 83.4425C111.698 76.2608 105.876 70.439 98.694 70.439C91.5123 70.439 85.6904 76.2608 85.6904 83.4425C85.6904 90.6242 91.5123 96.4461 98.694 96.4461Z"
          fill="#5D75F9"
        />
        <path
          d="M102.297 82.6124V80.1237C102.297 79.1682 101.917 78.2518 101.242 77.5762C100.566 76.9006 99.6499 76.521 98.6944 76.521C97.7389 76.521 96.8225 76.9006 96.1469 77.5762C95.4712 78.2518 95.0917 79.1682 95.0917 80.1237V82.6124C94.6099 82.6335 94.1549 82.8398 93.8214 83.1881C93.4879 83.5365 93.3018 84.0001 93.3018 84.4823V90.3624H104.087V84.4823C104.087 84.0001 103.901 83.5365 103.567 83.1881C103.234 82.8398 102.779 82.6335 102.297 82.6124ZM98.6944 77.7892C99.3132 77.7902 99.9064 78.0365 100.344 78.474C100.782 78.9116 101.028 79.5048 101.029 80.1237V82.6086H96.3599V80.1237C96.3604 79.5047 96.6065 78.9112 97.0442 78.4735C97.4819 78.0358 98.0754 77.7897 98.6944 77.7892Z"
          fill="white"
        />
        <path
          d="M99.6635 85.5162C99.6627 85.3497 99.6191 85.1863 99.5368 85.0416C99.4545 84.8969 99.3363 84.7759 99.1937 84.6901C99.051 84.6043 98.8887 84.5567 98.7223 84.5519C98.556 84.5471 98.3911 84.5852 98.2438 84.6626C98.0964 84.74 97.9715 84.854 97.8809 84.9937C97.7904 85.1334 97.7374 85.294 97.727 85.4601C97.7166 85.6262 97.7491 85.7922 97.8215 85.9421C97.8939 86.092 98.0036 86.2208 98.1402 86.3159V88.4238H99.2312V86.3159C99.3633 86.2282 99.4718 86.1094 99.5472 85.97C99.6225 85.8305 99.6625 85.6747 99.6635 85.5162Z"
          fill="#5D75F9"
        />
        <path
          d="M165.31 121.229H92.7607C92.7102 121.229 92.6618 121.209 92.6261 121.173C92.5904 121.137 92.5703 121.089 92.5703 121.039C92.5703 120.988 92.5904 120.94 92.6261 120.904C92.6618 120.868 92.7102 120.848 92.7607 120.848H165.31C165.36 120.848 165.408 120.868 165.444 120.904C165.48 120.94 165.5 120.988 165.5 121.039C165.5 121.089 165.48 121.137 165.444 121.173C165.408 121.209 165.36 121.229 165.31 121.229Z"
          fill="#CBCBCB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_34125">
          <rect width="165" height="121.307" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
