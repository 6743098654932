import { yupResolver } from '@hookform/resolvers/yup';
import ProfileUpdateForm from 'components/Forms/ProfileUpdateForm';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UpdateProfileSchema } from 'schemas/profile';
import { listCountries, listStates, listTimezones } from 'services/location';
import { getProfile, update as updateProfile } from 'services/patient';
import { getTokenSelector } from 'state/auth';
import { requestState } from 'state/request';

export default function Profile() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [request, setRequest] = useRecoilState(requestState);

  // Local state
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState(null);

  // RRD
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdateProfileSchema),
  });

  const watchCountry = watch('address.country');

  // RQ
  const queryClient = useQueryClient();
  const getProfileQuery = useQuery(['getProfile', token], () => getProfile(token));
  const listCountriesQuery = useQuery(['listCountries', token], () => listCountries(token), {
    refetchOnMount: false,
  });

  const countryID = watchCountry
    ? watchCountry
    : getProfileQuery?.data?.address?.country?.id || null;

  const listTimezonesQuery = useQuery(
    ['listTimezones', token, countryID],
    () => listTimezones(token, countryID),
    {
      refetchOnMount: false,
      enabled: getProfileQuery.isSuccess,
    },
  );
  const listStatesQuery = useQuery(
    ['listStates', token, countryID],
    () => listStates(token, countryID),
    {
      refetchOnMount: false,
      enabled: getProfileQuery.isSuccess,
    },
  );

  const isAllDataFetched =
    getProfileQuery.data &&
    listCountriesQuery.data &&
    listStatesQuery.data &&
    listTimezonesQuery.data;

  const updateProfileMutation = useMutation((data) => updateProfile(token, data, file), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['userStatus', token]);
      toast.success('La información se guardó exitosamente.');

      // UPDATE REQUEST STATE
      const _request = { ...request };
      _request['profileCompleted'] = true;
      setRequest(_request);

      navigate('tags');
    },
    onError: () => toast.error('Algo salió mal.'),
  });

  useEffect(() => {
    if (isAllDataFetched && !isSuccess) {
      const data = getProfileQuery.data;

      if (!data?.profile_completed) {
        reset({
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data?.email,
        });
      }

      if (data?.profile_completed) {
        reset({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          biography: data.biography,
          cellPhone: data.cell_phone,
          address: {
            country: data.address.country.id,
            timezone: data.address.timezone.id,
            state: data.address.state.id,
            zipCode: data.address.zip_code,
          },
        });
      }
      setIsSuccess(true);
    }
  }, [getProfileQuery, isAllDataFetched, isSuccess, reset]);

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      biography,
      cellPhone,
      address: { state, country, timezone, zipCode },
    } = data;
    const updatedProfile = {
      first_name: firstName,
      last_name: lastName,
      email,
      biography,
      cell_phone: cellPhone,
      address: {
        country,
        timezone,
        state,
        zip_code: zipCode,
        default: true,
      },
    };
    updateProfileMutation.mutate(updatedProfile);
  };

  const disabled = updateProfileMutation.isLoading;

  if (!isAllDataFetched && !watchCountry) {
    return (
      <div className="w-full flex justify-center mt-2">
        <Loader className="h-10 w-10 text-primary" />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <ProfileUpdateForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setFile={setFile}
        disabled={disabled}
        photoURL={getProfileQuery?.data?.photo_URL}
        register={register}
        countries={listCountriesQuery.data}
        states={listStatesQuery.data}
        timezones={listTimezonesQuery.data}
        watchCountry={watchCountry}
        errors={errors}
      />
    );
  }

  return null;
}
