import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Ancord({
  href,
  children,
  color = 'text-indigo-600',
  weight = 'font-medium',
}) {
  return (
    <Link to={href} className={`${weight} ${color} hover:text-indigo-500`}>
      {children}
    </Link>
  );
}

Ancord.propTypes = {
  href: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string.isRequired,
};
