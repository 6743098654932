export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[parseInt(i)];

    // Swap
    array[parseInt(i)] = array[parseInt(j)];
    array[parseInt(j)] = temp;
  }
  return array;
};

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
