import React from 'react';

export default function Frame03() {
  return (
    <svg
      width="138"
      height="119"
      viewBox="0 0 138 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1744_34370)">
        <path
          d="M10.3613 108.671C11.157 108.95 12.0113 109.019 12.8417 108.872C13.6721 108.725 14.4506 108.367 15.1022 107.832C16.7622 106.437 17.2832 104.143 17.7047 102.016L18.9574 95.7329L16.3366 97.5429C14.4495 98.8402 12.5219 100.178 11.2166 102.061C9.91128 103.944 9.3397 106.52 10.3896 108.547"
          fill="#E6E6E6"
        />
        <path
          d="M10.7671 116.72C10.4367 114.318 10.0982 111.885 10.3374 109.453C10.54 107.294 11.2028 105.186 12.5426 103.457C13.2531 102.544 14.1165 101.761 15.0945 101.143C15.3499 100.983 15.585 101.388 15.3296 101.548C13.6319 102.622 12.3189 104.206 11.5798 106.074C10.7691 108.15 10.6313 110.414 10.7691 112.621C10.8542 113.955 11.0346 115.28 11.215 116.604C11.2297 116.664 11.2211 116.727 11.1909 116.78C11.1608 116.834 11.1114 116.873 11.0528 116.892C10.9927 116.908 10.9285 116.9 10.8742 116.869C10.82 116.838 10.78 116.788 10.763 116.728L10.7671 116.72Z"
          fill="#F2F2F2"
        />
        <path
          d="M13.1426 112.862C13.4845 113.38 13.9538 113.801 14.5054 114.085C15.057 114.369 15.6724 114.506 16.2924 114.484C17.8855 114.407 19.2132 113.296 20.409 112.24L23.946 109.117L21.6049 109.005C19.9226 108.924 18.1957 108.847 16.5924 109.368C14.9891 109.889 13.5094 111.135 13.2155 112.795"
          fill="#E6E6E6"
        />
        <path
          d="M9.83477 118.102C11.4239 115.29 13.2663 112.167 16.56 111.168C17.4784 110.889 18.4419 110.79 19.3977 110.876C19.6977 110.902 19.6227 111.364 19.3227 111.338C17.7216 111.203 16.123 111.625 14.7966 112.532C13.5237 113.399 12.5326 114.603 11.6934 115.88C11.1786 116.663 10.7185 117.479 10.2584 118.294C10.1165 118.554 9.6868 118.371 9.83477 118.102Z"
          fill="#F2F2F2"
        />
        <path
          d="M136.98 83.3931H49.3753C49.1065 83.3931 48.8487 83.2863 48.6586 83.0962C48.4686 82.9062 48.3618 82.6484 48.3618 82.3796V1.01345C48.3618 0.744669 48.4686 0.486893 48.6586 0.296834C48.8487 0.106774 49.1065 0 49.3753 0L136.98 0C137.249 0 137.507 0.106774 137.697 0.296834C137.887 0.486893 137.994 0.744669 137.994 1.01345V82.3756C137.994 82.6443 137.887 82.9021 137.697 83.0922C137.507 83.2822 137.249 83.389 136.98 83.389V83.3931Z"
          fill="white"
        />
        <path
          d="M136.98 83.3931H49.3753C49.1065 83.3931 48.8487 83.2863 48.6586 83.0962C48.4686 82.9062 48.3618 82.6484 48.3618 82.3796V1.01345C48.3618 0.744669 48.4686 0.486893 48.6586 0.296834C48.8487 0.106774 49.1065 0 49.3753 0L136.98 0C137.249 0 137.507 0.106774 137.697 0.296834C137.887 0.486893 137.994 0.744669 137.994 1.01345V82.3756C137.994 82.6443 137.887 82.9021 137.697 83.0922C137.507 83.2822 137.249 83.389 136.98 83.389V83.3931ZM49.3753 0.405382C49.214 0.405382 49.0593 0.469446 48.9453 0.583482C48.8313 0.697518 48.7672 0.852183 48.7672 1.01345V82.3756C48.7672 82.5368 48.8313 82.6915 48.9453 82.8055C49.0593 82.9196 49.214 82.9836 49.3753 82.9836H136.98C137.142 82.9836 137.296 82.9196 137.41 82.8055C137.524 82.6915 137.588 82.5368 137.588 82.3756V1.01345C137.588 0.852183 137.524 0.697518 137.41 0.583482C137.296 0.469446 137.142 0.405382 136.98 0.405382H49.3753Z"
          fill="#CACACA"
        />
        <path
          d="M90.7712 21.1406C90.5879 21.1406 90.4121 21.2134 90.2825 21.3431C90.1529 21.4727 90.0801 21.6485 90.0801 21.8318C90.0801 22.0151 90.1529 22.1909 90.2825 22.3205C90.4121 22.4502 90.5879 22.523 90.7712 22.523H128.709C128.892 22.523 129.068 22.4502 129.198 22.3205C129.327 22.1909 129.4 22.0151 129.4 21.8318C129.4 21.6485 129.327 21.4727 129.198 21.3431C129.068 21.2134 128.892 21.1406 128.709 21.1406H90.7712Z"
          fill="#E4E4E4"
        />
        <path
          d="M90.7712 25.2896C90.5879 25.2896 90.4121 25.3624 90.2825 25.492C90.1529 25.6216 90.0801 25.7974 90.0801 25.9807C90.0801 26.164 90.1529 26.3398 90.2825 26.4695C90.4121 26.5991 90.5879 26.6719 90.7712 26.6719H110.136C110.32 26.6719 110.495 26.5991 110.625 26.4695C110.755 26.3398 110.827 26.164 110.827 25.9807C110.827 25.7974 110.755 25.6216 110.625 25.492C110.495 25.3624 110.32 25.2896 110.136 25.2896H90.7712Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 47.5962C57.4336 47.5962 57.2578 47.669 57.1282 47.7986C56.9986 47.9283 56.9258 48.1041 56.9258 48.2874C56.9258 48.4707 56.9986 48.6465 57.1282 48.7761C57.2578 48.9057 57.4336 48.9785 57.617 48.9785H128.739C128.922 48.9785 129.098 48.9057 129.228 48.7761C129.357 48.6465 129.43 48.4707 129.43 48.2874C129.43 48.1041 129.357 47.9283 129.228 47.7986C129.098 47.669 128.922 47.5962 128.739 47.5962H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 51.7451C57.4336 51.7451 57.2578 51.8179 57.1282 51.9476C56.9986 52.0772 56.9258 52.253 56.9258 52.4363C56.9258 52.6196 56.9986 52.7954 57.1282 52.925C57.2578 53.0547 57.4336 53.1275 57.617 53.1275H110.173C110.356 53.1275 110.532 53.0547 110.661 52.925C110.791 52.7954 110.864 52.6196 110.864 52.4363C110.864 52.253 110.791 52.0772 110.661 51.9476C110.532 51.8179 110.356 51.7451 110.173 51.7451H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 55.7988C57.4336 55.7988 57.2578 55.8716 57.1282 56.0013C56.9986 56.1309 56.9258 56.3067 56.9258 56.49C56.9258 56.6733 56.9986 56.8491 57.1282 56.9787C57.2578 57.1084 57.4336 57.1812 57.617 57.1812H128.739C128.922 57.1812 129.098 57.1084 129.228 56.9787C129.357 56.8491 129.43 56.6733 129.43 56.49C129.43 56.3067 129.357 56.1309 129.228 56.0013C129.098 55.8716 128.922 55.7988 128.739 55.7988H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 59.9458C57.4336 59.9458 57.2578 60.0186 57.1282 60.1482C56.9986 60.2779 56.9258 60.4537 56.9258 60.637C56.9258 60.8203 56.9986 60.9961 57.1282 61.1257C57.2578 61.2553 57.4336 61.3282 57.617 61.3282H110.173C110.356 61.3282 110.532 61.2553 110.661 61.1257C110.791 60.9961 110.864 60.8203 110.864 60.637C110.864 60.4537 110.791 60.2779 110.661 60.1482C110.532 60.0186 110.356 59.9458 110.173 59.9458H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 64.1089C57.4336 64.1089 57.2578 64.1817 57.1282 64.3113C56.9986 64.4409 56.9258 64.6168 56.9258 64.8001C56.9258 64.9834 56.9986 65.1592 57.1282 65.2888C57.2578 65.4184 57.4336 65.4912 57.617 65.4912H128.739C128.922 65.4912 129.098 65.4184 129.228 65.2888C129.357 65.1592 129.43 64.9834 129.43 64.8001C129.43 64.6168 129.357 64.4409 129.228 64.3113C129.098 64.1817 128.922 64.1089 128.739 64.1089H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M57.617 68.2563C57.4336 68.2563 57.2578 68.3292 57.1282 68.4588C56.9986 68.5884 56.9258 68.7642 56.9258 68.9475C56.9258 69.1309 56.9986 69.3067 57.1282 69.4363C57.2578 69.5659 57.4336 69.6387 57.617 69.6387H110.173C110.356 69.6387 110.532 69.5659 110.661 69.4363C110.791 69.3067 110.864 69.1309 110.864 68.9475C110.864 68.7642 110.791 68.5884 110.661 68.4588C110.532 68.3292 110.356 68.2563 110.173 68.2563H57.617Z"
          fill="#E4E4E4"
        />
        <path
          d="M68.8825 33.8373C66.9182 33.8373 64.998 33.2548 63.3647 32.1635C61.7314 31.0722 60.4584 29.5211 59.7067 27.7062C58.955 25.8914 58.7583 23.8945 59.1415 21.9679C59.5247 20.0413 60.4707 18.2716 61.8597 16.8826C63.2487 15.4936 65.0183 14.5477 66.9449 14.1645C68.8715 13.7813 70.8685 13.9779 72.6833 14.7297C74.4981 15.4814 76.0492 16.7544 77.1406 18.3876C78.2319 20.0209 78.8144 21.9412 78.8144 23.9055C78.8117 26.5388 77.7644 29.0634 75.9024 30.9254C74.0404 32.7874 71.5158 33.8347 68.8825 33.8373V33.8373Z"
          fill="#5D75F9"
        />
        <path
          d="M38.7343 71.3916C38.7189 71.0658 38.6395 70.7462 38.5006 70.451C38.3617 70.1559 38.166 69.891 37.9247 69.6716C37.6834 69.4521 37.4012 69.2823 37.0942 69.1719C36.7873 69.0615 36.4616 69.0127 36.1358 69.0283C35.9898 69.0358 35.8447 69.0555 35.702 69.087L32.4306 64.4556L29.1328 65.7649L33.8494 72.2389C34.0255 72.823 34.4093 73.3225 34.9284 73.643C35.4475 73.9635 36.066 74.0829 36.6671 73.9787C37.2682 73.8744 37.8103 73.5537 38.1912 73.0771C38.572 72.6005 38.7652 72.0009 38.7343 71.3916Z"
          fill="#A0616A"
        />
        <path
          d="M32.3357 71.0476L22.4038 58.1625L26.1272 46.4652C26.3988 43.5242 28.2393 42.7033 28.3183 42.6688L28.4379 42.6182L31.681 51.2548L29.3014 57.595L35.1389 67.4133L32.3357 71.0476Z"
          fill="#3F3D56"
        />
        <path
          d="M66.8334 31.3241C66.5099 31.3645 66.1976 31.4682 65.9142 31.6293C65.6308 31.7904 65.382 32.0057 65.1819 32.2631C64.9818 32.5204 64.8344 32.8146 64.748 33.1289C64.6617 33.4433 64.6382 33.7715 64.6788 34.0949C64.6966 34.2401 64.7278 34.3832 64.772 34.5226L60.4121 38.1548L61.9728 41.3411L68.0536 36.1279C68.6237 35.9092 69.0939 35.4889 69.375 34.9467C69.6561 34.4045 69.7285 33.778 69.5786 33.186C69.4287 32.594 69.0669 32.0774 68.5617 31.7343C68.0565 31.3912 67.443 31.2452 66.8374 31.3241H66.8334Z"
          fill="#EFD1BA"
        />
        <path
          d="M66.985 37.729L54.9046 48.6297L42.9459 45.8224C39.9927 45.7778 39.0319 43.9982 38.9934 43.9313L38.9326 43.8158L47.2936 39.918L53.8 41.799L63.1359 35.2217L66.985 37.729Z"
          fill="#3F3D56"
        />
        <path d="M46.0615 115.217H49.3066L50.8511 102.697H46.0615V115.217Z" fill="#EFD1BA" />
        <path
          d="M55.5656 118.23H45.3662V114.29H52.6996C53.0761 114.289 53.449 114.363 53.797 114.507C54.1449 114.65 54.4611 114.862 54.7273 115.128C54.9936 115.394 55.2047 115.71 55.3485 116.058C55.4924 116.406 55.5661 116.779 55.5656 117.156V117.156V118.234V118.23Z"
          fill="#2F2E41"
        />
        <path d="M33.0894 115.217H36.3344L37.8789 102.697H33.0894V115.217Z" fill="#EFD1BA" />
        <path
          d="M42.5934 118.23H32.394V114.29H39.7274C40.1039 114.289 40.4768 114.363 40.8248 114.507C41.1728 114.65 41.4889 114.862 41.7552 115.128C42.0214 115.394 42.2325 115.71 42.3764 116.058C42.5202 116.406 42.594 116.779 42.5934 117.156V117.156V118.234V118.23Z"
          fill="#2F2E41"
        />
        <path
          d="M31.9341 69.5205L32.1996 88.0525L32.4631 112.408L38.2884 111.879L41.2011 78.2565L44.9063 111.879H50.9201L51.5262 77.993L49.4081 70.5806L31.9341 69.5205Z"
          fill="#2F2E41"
        />
        <path
          d="M43.3333 72.0098C36.9972 72.0098 31.1719 69.1417 31.0847 69.1032L31.0198 69.0707L30.4868 56.3012C30.3327 55.8492 27.2964 46.9511 26.7816 44.1215C26.2607 41.2555 33.8129 38.7421 34.7311 38.4462L34.9338 36.1416L43.3982 35.2295L44.4704 38.1786L47.5108 39.3178C47.8551 39.4471 48.1401 39.6982 48.3115 40.0236C48.483 40.349 48.5291 40.7261 48.4411 41.0832L46.7547 47.9443L50.8754 70.6457L49.9877 70.6842C47.8614 71.653 45.5649 72.0098 43.3333 72.0098Z"
          fill="#3F3D56"
        />
        <path
          d="M39.5006 33.7746C42.8656 33.7746 45.5935 31.0468 45.5935 27.6817C45.5935 24.3167 42.8656 21.5889 39.5006 21.5889C36.1356 21.5889 33.4077 24.3167 33.4077 27.6817C33.4077 31.0468 36.1356 33.7746 39.5006 33.7746Z"
          fill="#EFD1BA"
        />
        <path
          d="M34.5672 33.5817C35.7286 34.8181 37.8852 34.1533 38.0352 32.4649C38.0484 32.3335 38.0484 32.201 38.0352 32.0696C37.9561 31.3217 37.5244 30.6508 37.6298 29.8522C37.6539 29.6555 37.7271 29.468 37.8426 29.307C38.7689 28.0685 40.9398 29.8603 41.8134 28.7394C42.3485 28.0523 41.7201 26.9679 42.1316 26.1997C42.6728 25.1862 44.2801 25.6849 45.2875 25.1295C46.4084 24.5214 46.3415 22.7925 45.6037 21.7466C44.7037 20.4696 43.1248 19.7886 41.5681 19.6913C40.0114 19.594 38.4629 20.0136 37.0076 20.5811C35.3556 21.2257 33.7159 22.1155 32.6983 23.5688C31.4619 25.3362 31.3423 27.7118 31.9626 29.7792C32.3335 31.0359 33.6186 32.5703 34.5672 33.5817Z"
          fill="#2F2E41"
        />
        <path
          d="M77.4279 118.388H0.202691C0.148934 118.388 0.0973787 118.366 0.0593668 118.328C0.0213549 118.29 0 118.239 0 118.185C0 118.131 0.0213549 118.08 0.0593668 118.042C0.0973787 118.004 0.148934 117.982 0.202691 117.982H77.4279C77.4816 117.982 77.5332 118.004 77.5712 118.042C77.6092 118.08 77.6306 118.131 77.6306 118.185C77.6306 118.239 77.6092 118.29 77.5712 118.328C77.5332 118.366 77.4816 118.388 77.4279 118.388Z"
          fill="#CACACA"
        />
        <path
          d="M68.307 27.6571C68.1282 27.6576 67.9558 27.5902 67.8246 27.4686L64.5491 24.4283C64.4162 24.2991 64.339 24.1231 64.3342 23.9378C64.3294 23.7525 64.3973 23.5727 64.5234 23.4369C64.6494 23.301 64.8237 23.2199 65.0088 23.2108C65.1939 23.2018 65.3752 23.2655 65.5139 23.3885L68.2888 25.9647L73.7797 20.4718C73.8453 20.4052 73.9234 20.3522 74.0095 20.3159C74.0955 20.2795 74.1879 20.2604 74.2814 20.2598C74.3748 20.2591 74.4675 20.2769 74.5541 20.312C74.6407 20.3472 74.7195 20.399 74.786 20.4647C74.8526 20.5303 74.9056 20.6084 74.9419 20.6944C74.9783 20.7805 74.9974 20.8729 74.998 20.9664C74.9987 21.0598 74.9809 21.1525 74.9458 21.2391C74.9106 21.3257 74.8588 21.4045 74.7931 21.471L68.8198 27.4423C68.7532 27.5108 68.6735 27.5651 68.5854 27.6021C68.4972 27.639 68.4026 27.6577 68.307 27.6571V27.6571Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_34370">
          <rect width="138" height="118.418" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
