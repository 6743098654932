import React from 'react';
import Confetti from 'react-confetti';

import Content from '../../../components/Content';
import Title from '../../../components/Title';

export default function Finalized() {
  return (
    <div className="flex flex-col md:flex-row box-border mt-4">
      <div className="flex-1 md:pr-4">
        <Confetti width={window.innerWidth} height={window.innerHeight} />
        <Title className=" leading-6 text-2xl text-primary">¡Completaste tu Acompañamiento!</Title>
        <br />
        <Content className="font-default leading-5 text-xl">
          Estamos muy orgullosos de ti, fue un camino duro, pero lo lograste!
        </Content>
      </div>
      <div className="flex-1 mt-8 md:mt-0">
        {/* <BasicCard
          firstText="Ver mis estadisticas"
          icon={<ArrowRightIcon className="h-6 w-6 text-white" />}
          callback={() => navigate('tasks')}
        /> */}
      </div>
    </div>
  );
}

Finalized.propTypes = {};
