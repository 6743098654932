import BasicLayout from 'components/BasicLayout';
import Loader from 'components/Loader';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getProfile as getProfileService } from 'services/patient';
import { getTokenSelector } from 'state/auth';

export default function Profile() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const getProfileQuery = useQuery(['getProfile', token], () => getProfileService(token), {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: false,
  });

  return (
    <BasicLayout>
      {getProfileQuery.isSuccess ? (
        <Outlet
          context={{
            user: getProfileQuery.data,
            getProfileQuery,
          }}
        />
      ) : (
        <div className="grid grid-cols-3">
          <div className="col-span-1 col-start-2 flex justify-center mt-8">
            <Loader className="h-12 w-12 text-secondary" />
          </div>
        </div>
      )}
    </BasicLayout>
  );
}
