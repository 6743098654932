import { ChevronRightIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';

export default function PsychologistItem({
  image,
  name,
  location,
  psychologistType,
  psychologistTypeColor,
  amount,
  onClickCallback,
}) {
  return (
    <li className="w-full px-4 rounded-md border border-2 hover:border-secondary">
      <button
        className="w-full relative flex justify-between gap-x-6 py-5"
        onClick={onClickCallback}
      >
        <div className="flex gap-x-4">
          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover"
            src={image}
            alt=""
          />
          <div className="min-w-0 text-left">
            <Content className="text-sm font-semibold leading-6 text-gray-900">{name}</Content>
            <Content className="mt-1 flex text-xs leading-5 text-gray-500">{location}</Content>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <Content className={`text-sm font-bold leading-6 text-${psychologistTypeColor}`}>
              {psychologistType}
            </Content>
            <Content className="mt-1 text-xs leading-5 text-gray-500">{amount}</Content>
          </div>
          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </div>
      </button>
    </li>
  );
}

PsychologistItem.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  psychologistType: PropTypes.string.isRequired,
  psychologistTypeColor: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func.isRequired,
};

PsychologistItem.defaultProps = {};
