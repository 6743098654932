import PropTypes from 'prop-types';
import React from 'react';

export default function TextArea({
  id,
  register,
  registerConfig,
  textLabel,
  descriptionLabel,
  labelClassName,
  textAreaClassName,
  placeholder,
  rows,
  cols,
  error,
  required,
}) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <label className={`font-default ${labelClassName}`} htmlFor="contact">
          {textLabel}
        </label>
        {required ? <p className="ml-1 text-pink-500">*</p> : null}
      </div>

      <textarea
        {...register(id, registerConfig)}
        className={`rounded-md font-default text-sm ${textAreaClassName} mt-2 focus:border-secondary focus:ring-secondary`}
        placeholder={placeholder}
        id={id}
        cols={cols}
        rows={rows}
      ></textarea>
      {descriptionLabel ? (
        <p className="mt-3 text-sm leading-6 text-gray-400">{descriptionLabel}</p>
      ) : null}

      {error && <p className="text-sm font-default font-medium text-pink-500 mt-2">{error}</p>}
    </div>
  );
}

TextArea.propTypes = {
  id: PropTypes.string,
  register: PropTypes.func.isRequired,
  registerConfig: PropTypes.object.isRequired,
  textLabel: PropTypes.string,
  descriptionLabel: PropTypes.any,
  labelClassName: PropTypes.string,
  textAreaClassName: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.any,
  cols: PropTypes.any,
  error: PropTypes.string,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  rows: '10',
  cols: '20',
  required: false,
};
