import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';
import Title from './Title';

export default function Header({ title, description, children }) {
  return (
    <div className="grid grid-cols-1  lg:grid-cols-8 justify-center items-center">
      <div className="col-span-1 lg:col-span-7">
        <Title className="text-title">{title}</Title>
        <Content className="text-content text-gray-500">{description}</Content>
      </div>
      {children}
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.any,
};
