import { ArrowRightIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Content from 'components/Content';
import Subtitle from 'components/Subtitle';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

moment.lang('es', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_',
    ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});

export default function InProgress({ nextSession, onClickCallback }) {
  const nextSessionStartDate = moment(nextSession.start_date);
  moment.locale('es');
  let nextSessionTextDay = nextSessionStartDate.format('dddd');
  nextSessionTextDay = nextSessionTextDay.charAt(0).toUpperCase() + nextSessionTextDay.slice(1);
  let nextSessionDate = nextSessionStartDate.format('DD [de] MMMM [del] YYYY [a las] HH:mm a');

  return (
    <div className="grid">
      <Subtitle className="text-subtitle text-gray-500">Proxima Sesión</Subtitle>
      <Button onClick={() => onClickCallback(nextSession)}>
        <div className="grid grid-cols-4 p-4 shadow-md hover:shadow-lg rounded-md border border-1 hover:border-2 border-gray-300 hover:border-secondary cursor-pointer">
          <div className="col-span-3 text-left">
            <Content className="text-content text-gray-400 font-bold">
              Sesión {nextSession.number}
            </Content>

            <Content className="text-content text-gray-500 text-sm">
              {nextSessionTextDay} {nextSessionDate}
            </Content>
          </div>
          <div className="col-span-1 flex justify-center items-center">
            <ArrowRightIcon className="text-secondary h-8 w-8" />
          </div>
        </div>
      </Button>
    </div>
  );
}

InProgress.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  nextSession: PropTypes.object.isRequired,
  onClickCallback: PropTypes.func,
};
