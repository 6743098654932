import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { checkStatus } from '../../services/autodiagnosis';
import { getTokenSelector } from '../../state/auth';
import { AUTODIAGNOSIS_COMPLETED } from '../../utils/statuses';

export default function Autodiagnosis() {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // RRD
  const navigate = useNavigate();

  // RQ
  const {
    data: checkStatusData,
    isLoading,
    isSuccess,
    refetch: refetchAutodiagnosisStatus,
  } = useQuery(['checkAutodiagnosisStatus', token], () => checkStatus(token), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess && !checkStatusData.is_authorized) {
      navigate('about');
    } else if (
      isSuccess &&
      checkStatusData.is_authorized &&
      checkStatusData.status !== AUTODIAGNOSIS_COMPLETED
    ) {
      navigate('flow');
    } else if (
      isSuccess &&
      checkStatusData.is_authorized &&
      checkStatusData.status === AUTODIAGNOSIS_COMPLETED
    ) {
      navigate('/app/accompaniment-request/');
    }
  }, [isSuccess, checkStatusData, navigate]);

  if (isLoading) {
    return <>Cargando...</>;
  }

  return (
    <>
      <Outlet
        context={{
          autodiagnosisID: checkStatusData?.id,
          autodiagnosisStatus: checkStatusData?.status,
          autodiagnosisNextQuestion: checkStatusData?.next_question,
          autodiagnosisProgress: checkStatusData?.progress,
          refetchAutodiagnosisStatus: refetchAutodiagnosisStatus,
        }}
      />
    </>
  );
}
