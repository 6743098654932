import Content from 'components/Content';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import React from 'react';

export default function DailyPhrase({ content, category, author }) {
  return (
    <div className="grid rounded-t-lg shadow-lg rounded-md bg-gradient-to-b from-indigo-700 via-indigo-500 to-secondary">
      <div className="rounded-full p-4">
        <Title className="text-title text-white">Frase del día</Title>
      </div>
      <div className="p-4 text-content">
        <Content className="text-white">{content}</Content>
        <Content className="mt-4 text-xs text-white">{category}</Content>
        <Content className="mt-1 text-white font-bold">{author}</Content>
      </div>
    </div>
  );
}

DailyPhrase.propTypes = {
  content: PropTypes.string.isRequired,
  category: PropTypes.string,
  author: PropTypes.string,
};
