export const getUserFriendlyCronExpression = (cronExpression) => {
  const cronExpressionArray = cronExpression.split(' ');
  const userFriendlyCronExpression = [];
  userFriendlyCronExpression[0] = cronExpressionArray[0];
  userFriendlyCronExpression[1] = cronExpressionArray[1];
  userFriendlyCronExpression[3] = cronExpressionArray[3];

  if (cronExpressionArray[2] === '*/15') {
    userFriendlyCronExpression[2] = 'Quincenal';
  } else {
    userFriendlyCronExpression[2] = 'Semanal';
  }

  if (cronExpressionArray[4] === '1') {
    userFriendlyCronExpression[4] = 'Lunes';
  } else if (cronExpressionArray[4] === '2') {
    userFriendlyCronExpression[4] = 'Martes';
  } else if (cronExpressionArray[4] === '3') {
    userFriendlyCronExpression[4] = 'Miercoles';
  } else if (cronExpressionArray[4] === '4') {
    userFriendlyCronExpression[4] = 'Jueves';
  } else if (cronExpressionArray[4] === '5') {
    userFriendlyCronExpression[4] = 'Viernes';
  }

  // cronExpressionArray[]
  //   let userFriendlyCronExpression = ''
  return userFriendlyCronExpression;
};
