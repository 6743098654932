import PropTypes from 'prop-types';
import React from 'react';

import Ancord from './Ancord';

export default function EmptyMessage({ text, actionText, actionHref }) {
  return (
    <div className="w-full flex flex-col items-center py-4">
      <p className="font-default font-normal text-sm text-gray-400 leading-5 ">{text}</p>
      {actionText && (
        <Ancord color="text-primary text-sm" href={actionHref}>
          {actionText}
        </Ancord>
      )}
    </div>
  );
}

EmptyMessage.propTypes = {
  text: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  actionHref: PropTypes.string,
};
