import Content from 'components/Content';
import ImageCard from 'components/ImageCard';
import React from 'react';

export default function NoPayment() {
  return (
    <ImageCard title="¡Falto de pago!" className="bg-yellow-500" callback={() => true}>
      <Content className="text-content text-white">
        Tu acompañamiento se encuentra en pausa debido a que no se realizo el pago de la sesión
      </Content>
    </ImageCard>
  );
}

NoPayment.propTypes = {
  // navigate: PropTypes.func,
};
