import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const CONTRACTS_PATH = 'contracts/';
const DEFAULT_CONTRACT = 1;

export const get = async (token) => {
  const { data } = await axios.get(`${API_URL}${CONTRACTS_PATH}${DEFAULT_CONTRACT}/`, {
    headers: headers(token),
  });
  return data;
};
