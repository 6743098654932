import * as Yup from 'yup';

const requiredFieldMessage = 'Este campo es requerido.';

export const UpdateProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredFieldMessage),
  lastName: Yup.string().required(requiredFieldMessage),
  address: Yup.object({
    country: Yup.string().required(requiredFieldMessage),
    state: Yup.string().required(requiredFieldMessage),
    // city: Yup.string().required(requiredFieldMessage),
    timezone: Yup.string().required(requiredFieldMessage),
    // street: Yup.string().required(requiredFieldMessage),
    // numExt: Yup.string().required(requiredFieldMessage),
    // numInt: Yup.string(),
    zipCode: Yup.string().required(requiredFieldMessage),
  }),
  email: Yup.string().email('Invalid email').required(requiredFieldMessage),
  cellPhone: Yup.string().required(requiredFieldMessage),
  // homePhone: Yup.string().nullable().default(''),
});
