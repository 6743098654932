import { RadioGroup, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// import Frame05 from '../../../assets/images/Frame05';
import Button from '../../../components/Button';
import Content from '../../../components/Content';
import CustomRadioGroup from '../../../components/Forms/CustomRadioGroup';
import ProgressBar from '../../../components/ProgressBar';
import TextArea from '../../../components/TextArea';
// import StepAnswer from '../../../components/StepAnswer';
import Title from '../../../components/Title';
import { AnswerSchema } from '../../../schemas/autodiagnosis';
import { createOrUpdate as createOrUpdateAnswer } from '../../../services/autodiagnosisAnswer';
import { get as getQuestion } from '../../../services/questions';
import { getTokenSelector } from '../../../state/auth';
import { AUTODIAGNOSIS_PROGRESS_PHRASES, QUESTION_TYPES } from '../../../utils/constants';

const ACTION_CURRENT = 'current';
const ACTION_PREVIOUS = 'previous';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Flow() {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // Outlet Context
  const { autodiagnosisNextQuestion, refetchAutodiagnosisStatus } = useOutletContext();

  // Local state
  const [error, setError] = useState(0);
  const [data, setData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(autodiagnosisNextQuestion);
  const [action, setAction] = useState(null);
  // const [isMutating, setIsMutating] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(AnswerSchema),
  });

  // RRD
  const navigate = useNavigate();

  // RQ
  const onSuccessRetrieveQuestion = (response) => {
    setAction(null);
    setCurrentQuestion(response?.order);
    setSelectedOption(response?.answer?.text);
    setData(response);
    if (response?.type === 4) {
      setValue('text', response?.answer?.text);
    }
    setIsLoadingPage(false);
  };

  const retrieveQuestionQuery = useQuery(
    ['retrieveQuestion', token, currentQuestion, action],
    () => getQuestion(token, currentQuestion, action),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: onSuccessRetrieveQuestion,
      onError: (error) => setError(error.message),
    },
  );

  const onSuccessCreateOrUpdateAnswer = () => {
    if (!data.is_final) {
      setSelectedOption(null);
      setCurrentQuestion(currentQuestion + 1);
      setAction(ACTION_CURRENT);
    } else {
      refetchAutodiagnosisStatus();
    }
  };
  const createOrUpdateAnswerMutation = useMutation((data) => createOrUpdateAnswer(token, data), {
    onSuccess: onSuccessCreateOrUpdateAnswer,
  });

  useEffect(() => {
    setAction(ACTION_CURRENT);
  }, []);

  useEffect(() => {
    if ([ACTION_CURRENT, ACTION_PREVIOUS].includes(action)) {
      setData(null);
      setIsLoadingPage(true);
      retrieveQuestionQuery.refetch();
    }

    if (action === 'MUTATE') {
      setIsLoadingPage(true);
      setData(null);
      const answer = data?.answer ? data?.answer?.id : null;
      createOrUpdateAnswerMutation.mutate({
        question: data?.id,
        answer,
        text: selectedOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleMutate = (option) => {
    setSelectedOption(option);
    setAction('MUTATE');
  };

  const buildOption = (option, index) => (
    <RadioGroup.Option
      key={index}
      value={option}
      className={({ checked, active }) =>
        classNames(
          checked ? 'border-transparent' : 'border-gray-300',
          active ? 'ring-2 ring-indigo-500' : '',
          'relative block bg-white border rounded-lg shadow-sm px-6 py-2 md:py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none',
        )
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex items-center">
            <div className="text-sm">
              <RadioGroup.Label as="p" className="font-medium text-gray-900">
                {data?.type === 2 ? option : QUESTION_TYPES[`${data?.type}`][`${option}`].label}
              </RadioGroup.Label>
              {data?.type === 2 ? null : (
                <RadioGroup.Description as="div" className="text-gray-500">
                  <p className="sm:inline">
                    {QUESTION_TYPES[`${data?.type}`][`${option}`].description}
                  </p>
                </RadioGroup.Description>
              )}
            </div>
          </div>
          <div
            className={classNames(
              active ? 'border' : 'border-2',
              checked ? 'border-indigo-500' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none',
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );

  if (error) {
    return (
      <div className="w-full flex justify-center items-center">
        <div className="max-w-lg">
          <p className="text-red-500 font-default">Algo salio mal, por favor intente más tarde.</p>
          <br />
          <br />
          <Button
            onClick={() => navigate('/app/autodiagnosis')}
            className="text-white bg-secondary text-base leading-6 font-medium border border-gray-300 rounded-md"
          >
            Recargar
          </Button>
        </div>
      </div>
    );
  }

  let progressPhrase = '';
  if (data?.progress) {
    const floorProgress = Math.floor(data?.progress / AUTODIAGNOSIS_PROGRESS_PHRASES.length);
    progressPhrase = AUTODIAGNOSIS_PROGRESS_PHRASES[String(floorProgress)];
  }

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full flex justify-center items-center">
      <Transition
        as={React.Fragment}
        show={data == null && isLoadingPage}
        enter="transition-opacity ease-linear duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div></div>
      </Transition>
      <Transition
        as={React.Fragment}
        show={data !== null && !isLoadingPage}
        enter="transition-opacity ease-linear duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-2xl flex-1">
          <div className="flex flex-col items-center">
            {/* {isNew && <Frame05 />} */}
            <Title className="text-lg md:text-2xl text-primary text-center leading-7 font-medium ">
              {data?.text}
            </Title>
            <Content className="mt-8 text-center">{data?.description}</Content>

            {data?.type === 4 ? (
              <form
                onSubmit={handleSubmit(({ text }) => handleMutate(text))}
                method="POST"
                className="w-full"
              >
                <div className="flex flex-col w-full ">
                  <TextArea
                    id="text"
                    register={register}
                    registerConfig={{ required: true }}
                    error={errors?.text?.message}
                    className="w-full"
                  />
                  <div className="mt-4 w-24">
                    <Button primary type="submit" className="text-sm rounded-md">
                      Guardar
                    </Button>
                  </div>
                </div>
              </form>
            ) : (
              <CustomRadioGroup selected={selectedOption} setSelected={handleMutate}>
                {data?.options.map(buildOption)}
              </CustomRadioGroup>
            )}

            <div className="w-full relative flex justify-center">
              <div className="w-full fixed md:relative bottom-5 px-4 md:px-0 md:mt-10">
                <div className="w-full px-4 mb-4 md:px-0">
                  {/* TODO */}
                  <ProgressBar percentageBar={data?.progress}>{progressPhrase}</ProgressBar>
                </div>
                <div className="w-full flex flex-col md:flex-row-reverse justify-between">
                  <div className="w-full px-4 md:px-0">
                    <Button
                      // disabled={data?.order === 1}
                      onClick={() => {
                        if (data?.order > 1) {
                          setSelectedOption(null);
                          setAction(ACTION_PREVIOUS);
                        }
                        // if (data?.order === 1) navigate
                      }}
                      boxShadow={true}
                      className="text-secondary bg-white text-base leading-6 font-medium border border-gray-300 rounded-md"
                    >
                      {data?.order === 1 ? 'Salir' : 'Regresar'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
