import PropTypes from 'prop-types';

import Content from './Content';
import Title from './Title';

export default function TimezonedNextSessionSchedule({
  title,
  startDateUTC,
  endDateUTC,
  timezone,
}) {
  const format = 'DD[ de ]MMMM[ del ]YYYY [a las] H:mm';

  const timezonedStartDate = startDateUTC.tz(timezone).format(format);
  const timezonedEndDate = endDateUTC.tz(timezone).format(format);

  return (
    <div className="bg-secondary rounded-md p-4">
      <Title className="text-title text-white text-lg mb-2 ">{title}</Title>
      <div className="grid gap-4">
        <div className="grid grid-cols-4 gap-1">
          <Content className="col-span-2 text-white text-sm text-content">Inicia:</Content>
          <Content className="col-span-4 text-gray-100">{timezonedStartDate}</Content>
        </div>
        <div className="grid grid-cols-4 gap-1">
          <Content className="col-span-2 text-white text-sm text-content">Termina:</Content>
          <Content className="col-span-4 text-gray-100">{timezonedEndDate}</Content>
        </div>
      </div>
    </div>
  );
}

TimezonedNextSessionSchedule.propTypes = {
  title: PropTypes.string,
  startDateUTC: PropTypes.any.isRequired,
  endDateUTC: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

TimezonedNextSessionSchedule.defaultProps = {
  title: 'Horarios Siguiente sesión:',
};
