import moment from 'moment';

export const SIMPLE_FORMAT = 1;
export const SIMPLE_FORMAT_WITH_TIME = 2;

const formats = {
  [SIMPLE_FORMAT]: 'DD/MMMM/YYYY',
  [SIMPLE_FORMAT_WITH_TIME]: 'DD/MMMM/YYYY',
};

export const formatDate = (date, format) => {
  const _date = moment(date);
  return _date.format(formats[String(format)]);
};
