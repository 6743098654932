import 'react-datepicker/dist/react-datepicker.css';

import { TrashIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Content from 'components/Content';
import Loader from 'components/Loader';
import TextArea from 'components/TextArea';
import Title from 'components/Title';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ActionRescheduleSchema } from 'schemas/actions';
import { getRescheduleStatus } from 'services/session';
import { createReschedule } from 'services/ticket';
import { getTokenSelector } from 'state/auth';
import { selectedSession } from 'state/session';

const TYPE_1 = 1;
const TYPE_2 = 2;
const contentByRescheduledType = {
  [TYPE_1]: (
    <>
      <Title className="text-primary">
        Estás dentro del rango de fechas para hacer un cambio de cita
      </Title>
      <br />
      <ul className="list-inside list-disc">
        <li className="mb-2">
          Querido Paciente, para nosotros el éxito de los proceso de acompañamiento son muy
          importantes, para ello el orden y la constancia son primordiales. Esto no quiere decir que
          no puedas reagendar. Estas dentro de los días aceptados para reagendar una cita, así que
          adelante.
        </li>
        <li className="mb-2">
          Una vez que tu Psicólogo Cather vea tu solicitud, este te estará enviando una propuesta de
          nuevo horario en el caso de que tenga disponibilidad en su agenda.
        </li>
        <li>En el caso de que no la tenga, tendrás que esperar a tu próxima cita programada.</li>
      </ul>
    </>
  ),
  [TYPE_2]: (
    <>
      <Title className="text-primary">Te quedan menos de 3 días para tu próxima cita</Title>
      <br />
      <Content>
        Querido Paciente, para nosotros el éxito de los proceso de acompañamiento son muy
        importantes, para ello el orden y la constancia son primordiales. Esto no quiere decir que
        no puedas reagendar, solo te pedimos que lo hagas con mayor anticipación dado a que lo estás
        haciendo con menos de 3 días para tu próxima cita.
        <br />
        <br />
        Al ser esta la situación, el Psicólogo Cather tiene la libertad de decidir si acepta o
        rechaza la solicitud de reagendar conforme a sus tiempos, ya que es probable que no seas su
        único paciente.
        <br />
        <br />
        En caso de que no acepte la solicitud de reagendar, el pago de la cita no se reembolsara y
        se tomará como una sesión cancelada, esto está en el contrato terapéutico que firmaste al
        iniciar con nosotros.
        <br />
        <br />
        En caso de ser aceptado, se te proporcionará un nuevo horario y te lo hará llegar tu
        Psicólogo Cather y adicional a ello se pagará como una cita adicional dado a la que ya
        tienes programada.
        <br />
        <br />
        En caso de que no tenga espacio, tu próxima cita será la que ya tienes programada con su
        correspondiente costo.
      </Content>
      <br />
      <Content className="text-md text-gray-400 leading-5">
        *La elección de los horarios queda a disponibilidad de este.
      </Content>
    </>
  ),
};

export default function Reschedule() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [session] = useRecoilState(selectedSession);

  // const getNewDate = () => {
  //   return { value: new Date() };
  // };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      options: [{ timestamp: new Date() }],
    },
    resolver: yupResolver(ActionRescheduleSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'options', // unique name for your Field Array
  });

  const getRescheduleStatusQuery = useQuery(
    ['getRescheduleStatus', token, session?.id],
    () => getRescheduleStatus(token, session?.id),
    {
      enabled: !!session?.id,
    },
  );

  const createRescheduleMutation = useMutation((data) => createReschedule(token, data), {
    onSuccess: async () => {
      toast.success('El ticket se creó con exito.');
      navigate('/app/sessions/detail/tickets');
    },
    onError: () => toast.error('Algo salió mal.'),
  });

  const rescheduleType = getRescheduleStatusQuery?.data?.reschedule_type;
  const content = contentByRescheduledType[`${rescheduleType}`];

  // RRD
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const parsedData = {
      title: `Reschedule Session #${session?.id}`,
      description: data.description,
      proposed_dates: data.options.map((option) => moment(option.timestamp).utc().format()),
    };

    createRescheduleMutation.mutate(parsedData);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className="overflow-y-auto">
      <Title className="text-lg text-secondary leading-6 mt-4 md:mt-0">Reagendar</Title>
      <br />
      {getRescheduleStatusQuery.isLoading ? (
        <div className="flex justify-center w-full">
          <Loader className="h-12 w-12 text-primary" />
        </div>
      ) : null}
      {getRescheduleStatusQuery.isSuccess ? (
        <>
          <Content className="text-md text-gray-500 leading-5">{content}</Content>
          <br />

          <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
            <TextArea
              id="description"
              labelClassName="text-sm text-gray-700 leading-5 font-medium"
              descriptionLabel="Cuéntanos por qué quisieras reagendar esta sesión."
              register={register}
              registerConfig={{ required: true }}
              error={errors.description?.message}
            />

            {fields.map((field, index) => {
              return (
                <div key={field.id}>
                  <div className="w-full flex justify-between">
                    <Content>Opción {index + 1}</Content>
                    {index !== 0 ? (
                      <button onClick={() => remove(index)}>
                        <TrashIcon className={`h-5 w-5 ml-1 mt-px text-red-500`} />
                      </button>
                    ) : null}
                  </div>

                  <Controller
                    control={control}
                    name={`options.${index}`}
                    render={(_props) => {
                      return (
                        <>
                          <DatePicker
                            className="w-full rounded-md border-primary"
                            selected={_props.field.value.timestamp}
                            onChange={(date) => {
                              _props.field.onChange({ timestamp: date });
                            }}
                            showTimeSelect
                            minDate={new Date()}
                            filterTime={filterPassedTime}
                            dateFormat="yyyy-MM-dd hh:mm aa"
                          />
                          {errors?.options?.[String(index)]?.message ? (
                            <p className="text-red-500 text-sm font-default">
                              {errors.options[String(index)].message}
                            </p>
                          ) : null}
                        </>
                      );
                    }}
                  />
                </div>
              );
            })}
            {errors?.options?.message ? (
              <p className="text-red-500 text-sm font-default">{errors.options.message}</p>
            ) : null}
            {fields.length > 2 ? null : (
              <>
                <Button
                  onClick={() => append({ timestamp: new Date() })}
                  className="text-white bg-secondary rounded-md"
                >
                  Agregar Opción
                </Button>
                <br />
                <br />
              </>
            )}

            <Button type="submit" primary className="rounded-md">
              Solicitar reagendar
            </Button>
          </form>
          <Button
            onClick={() => navigate('/app/sessions/detail')}
            className="mt-4 text-primary border border-gray-100 rounded-md"
          >
            Cerrar
          </Button>
        </>
      ) : null}
    </div>
  );
}
