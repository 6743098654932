import Content from 'components/Content';
import ImageCard from 'components/ImageCard';
import PropTypes from 'prop-types';
import React from 'react';

export default function Canceled({ reason }) {
  return (
    <ImageCard title="¡Tu tratamiento fue cancelado!" className="bg-red-500" callback={() => true}>
      <Content className="text-content text-white">{reason}</Content>
    </ImageCard>
  );
}

Canceled.propTypes = {
  reason: PropTypes.string,
};
