import PropTypes from 'prop-types';
import React from 'react';

export default function IconSpanItem({ icon, children }) {
  return (
    <div className="flex items-center pb-1">
      {icon}
      {children}
    </div>
  );
}

IconSpanItem.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
};

IconSpanItem.defaultProps = {};
