import { RadioGroup } from '@headlessui/react';
import PropTypes from 'prop-types';

export default function CustomRadioGroup({ selected, setSelected, children }) {
  return (
    <RadioGroup value={selected} onChange={setSelected} className="w-full">
      <RadioGroup.Label className="sr-only">Options</RadioGroup.Label>
      <div className="space-y-4">{children}</div>
    </RadioGroup>
  );
}

CustomRadioGroup.propTypes = {
  selected: PropTypes.any,
  setSelected: PropTypes.func.isRequired,
  children: PropTypes.any,
};

CustomRadioGroup.defaultProps = {};
