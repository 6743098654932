import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const TICKETS_PATH = 'tickets/';

export const createReschedule = async (token, body) => {
  return await axios.post(`${API_URL}${TICKETS_PATH}p/reschedule/`, body, {
    headers: headers(token),
  });
};

export const getSessionTickets = async (token, params) => {
  const { data } = await axios.get(`${API_URL}${TICKETS_PATH}p/sessions/`, {
    headers: headers(token),
    params,
  });
  return data;
};

export const createSessionCancel = async (token, sessionId, body) => {
  return await axios.post(`${API_URL}${TICKETS_PATH}p/session/${sessionId}/cancel/`, body, {
    headers: headers(token),
  });
};
