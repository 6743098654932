import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const requestState = atom({
  key: 'request',
  default: {
    profileCompleted: false,
    hasTags: false,
    psychologist: null,
    selectedStartTime: null,
    selectedEndTime: null,
    hasPayment: false,
    contractCheck: false,
    contractID: null,
    paymentMethod: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const getPsychologistSelector = selector({
  key: 'getPsychologist',
  get: ({ get }) => get(requestState)?.psychologist,
});

export const hasPaymentSelector = selector({
  key: 'hasPayment',
  get: ({ get }) => get(requestState)?.hasPayment,
});
