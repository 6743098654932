import BasicLayout from 'components/BasicLayout';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function Sessions() {
  return (
    <BasicLayout>
      <Outlet />
    </BasicLayout>
  );
}
