import { yupResolver } from '@hookform/resolvers/yup';
import ProfileUpdateForm from 'components/Forms/ProfileUpdateForm';
import Header from 'components/Header';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UpdateProfileSchema } from 'schemas/profile';
import { listCountries, listStates, listTimezones } from 'services/location';
import { update as updateProfile } from 'services/patient';
import { authState, getTokenSelector } from 'state/auth';

export default function ProfileUpdate() {
  // Global state
  const [auth, setAuth] = useRecoilState(authState);
  const token = useRecoilValue(getTokenSelector);

  // Local state
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [file, setFile] = useState(null);

  // Context
  const { user, getProfileQuery } = useOutletContext();

  // RRD
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdateProfileSchema),
    defaultValues: {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      homePhone: user.home_phone,
      cellPhone: user.cell_phone,
      address: {
        aka: user.address.aka,
        country: user?.address?.country?.id,
        timezone: user?.address?.timezone?.id,
        state: user?.address?.state?.id,
        city: user.address.city,
        street: user.address.street,
        numExt: user.address.ext_number,
        numInt: user.address.int_number,
        zipCode: user.address.zip_code,
      },
    },
  });

  // RQ
  const listCountriesQuery = useQuery(['listCountries', token], () => listCountries(token), {
    refetchOnMount: false,
  });
  const watchCountry = watch('address.country');
  const countryID = watchCountry ? watchCountry : user?.address?.country?.id;

  const listTimezonesQuery = useQuery(
    ['listTimezones', token, countryID],
    () => listTimezones(token, countryID),
    {
      refetchOnMount: false,
      enabled: !!countryID,
    },
  );
  const listStatesQuery = useQuery(
    ['listStates', token, countryID],
    () => listStates(token, countryID),
    {
      refetchOnMount: false,
      enabled: !!countryID,
    },
  );

  const isAllDataFetched =
    listCountriesQuery.data && listStatesQuery.data && listTimezonesQuery.data;

  const updateProfileMutation = useMutation((data) => updateProfile(token, data, file), {
    onSuccess: async (data) => {
      await getProfileQuery.refetch();
      let newAuth = JSON.parse(JSON.stringify(auth));
      let prevProfileCompleted = auth?.user?.profile_completed;
      newAuth.user.first_name = data.first_name;
      newAuth.user.last_name = data.last_name;
      newAuth.user.photo_URL = data.photo_URL;
      newAuth.user.profile_completed = true;
      setAuth(newAuth);
      toast.success('La información se guardó exitosamente.');
      if (!prevProfileCompleted) {
        navigate('/app');
      } else {
        navigate('/app/profile');
      }
    },
    onError: () => toast.error('Algo salió mal.'),
  });

  useEffect(() => {
    if (isAllDataFetched && !isLoadingPage) return;

    if (isLoadingPage && isAllDataFetched) {
      reset({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        homePhone: user.home_phone,
        cellPhone: user.cell_phone,
        address: {
          aka: user.address.aka,
          country: user.address.country?.id,
          timezone: user.address.timezone?.id,
          state: user.address.state?.id,
          city: user.address.city,
          street: user.address.street,
          numExt: user.address.ext_number,
          numInt: user.address.int_number,
          zipCode: user.address.zip_code,
        },
      });
      setIsLoadingPage(false);
    }
  }, [reset, isLoadingPage, user, isAllDataFetched, watchCountry]);

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      biography,
      cellPhone,
      homePhone,
      address: { state, country, city, timezone, street, numExt, numInt, zipCode },
    } = data;
    const updatedProfile = {
      first_name: firstName,
      last_name: lastName,
      email,
      biography,
      cell_phone: cellPhone,
      home_phone: homePhone || '',
      address: {
        country,
        timezone,
        state,
        city,
        street,
        ext_number: numExt,
        int_number: numInt,
        zip_code: zipCode,
        default: true,
      },
    };
    updateProfileMutation.mutate(updatedProfile);
  };

  const disabled = updateProfileMutation.isLoading;

  if (isLoadingPage)
    return (
      <div className="grid grid-cols-3">
        <div className="col-span-1 col-start-2 flex justify-center mt-8">
          <Loader className="h-12 w-12 text-secondary" />
        </div>
      </div>
    );

  if (!isLoadingPage)
    return (
      <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
        <Header title="Detalle del Perfil" description="Aquí podrás ver el detalle de tu perfil" />
        <ProfileUpdateForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          setFile={setFile}
          disabled={disabled}
          photoURL={user?.photo_URL}
          register={register}
          countries={listCountriesQuery.data}
          states={listStatesQuery.data}
          timezones={listTimezonesQuery.data}
          watchCountry={watchCountry}
          errors={errors}
        />
      </div>
    );

  return null;
}
