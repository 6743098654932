import SidebarStepsDesktop from 'components/Sidebar/SidebardStepsDesktop';
import SidebarStepsMobile from 'components/Sidebar/SidebardStepsMobile';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestState } from 'state/request';

export default function AccompanimentRequest() {
  // Global state
  const [accompanimentRequestState] = useRecoilState(requestState);

  const profileCompleted = accompanimentRequestState?.profileCompleted;
  const hasTags = accompanimentRequestState?.hasTags;
  const hasPsychologist = !!accompanimentRequestState?.psychologist;
  const hasContract = accompanimentRequestState?.contractCheck;
  const hasPayment = accompanimentRequestState?.hasPayment;

  return (
    <div className="grid justify-center">
      <div className="max-w-7xl grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-1 hidden lg:block">
          <SidebarStepsDesktop
            profileCompleted={profileCompleted}
            hasTags={hasTags}
            hasPsychologist={hasPsychologist}
            hasContract={hasContract}
            hasPayment={hasPayment}
          />
        </div>
        <div className="col-span-1 lg:hidden">
          <SidebarStepsMobile
            profileCompleted={profileCompleted}
            hasTags={hasTags}
            hasPsychologist={hasPsychologist}
            hasContract={hasContract}
            hasPayment={hasPayment}
          />
        </div>

        <div className="lg:col-span-3 w-full">
          <div className="w-full grid grid-cols-1 grid-rows-1">
            <div className="col-span-1 p-4 mt-8 md:mx-8">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
