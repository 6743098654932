import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const QUESTIONS_PATH = 'questions/';

export const get = async (token, id, action) => {
  try {
    const { data } = await axios.get(`${API_URL}${QUESTIONS_PATH}${id}/`, {
      headers: headers(token),
      params: {
        action,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error.response.data.question);
  }
};
