const HOST = process.env.REACT_APP_API_HOST;
const API_VERSION = 'v1/';

export const API_URL = `${HOST}${API_VERSION}`;

// TODO: Remove hardcoded price
export const PSYCHOLOGIST_DEGREES = {
  0: {
    degree: 'Becario',
    color: 'fourth',
    sessionCost: '20',
  },
  1: {
    degree: 'Dover',
    color: 'tertiary',
    sessionCost: '59',
  },
  2: {
    degree: 'SuperDover',
    color: 'secondary',
    sessionCost: '69',
  },
  3: {
    degree: 'UltraDover',
    color: 'primary',
    sessionCost: '79',
  },
};

// Question types
export const QUESTION_TYPES = {
  1: {
    0: {
      label: 'Ninguno',
      description: 'Para nada',
    },
    1: {
      label: 'Raramente',
      description: 'Raro, menos de un día o dos',
    },
    2: {
      label: 'Leve',
      description: 'Algunos días',
    },
    3: {
      label: 'Moderado',
      description: 'Más de la mitad de los días',
    },
    4: {
      label: 'Severo',
      description: 'Casi todos los días',
    },
  },
  3: {
    0: {
      label: 'No',
      description: 'No lo he hecho.',
    },
    1: {
      label: 'Si',
      description: 'Si lo he hecho.',
    },
  },
};

export const AUTODIAGNOSIS_PROGRESS_PHRASES = [
  '¡Muy bien! Este es solo el comienzo, sigue así',
  'Sigue así, tu progreso será reflejado conforme avances el cuestionario',
  'Recuerda que tus respuestas ayudan a que tu diagnóstico sea  más certero',
  'Ya casi estás a mitad del cuestionario',
  '¡Muy bien! Continua  respondiendo así',
  'Ya casi llegas al final del cuestionario',
  'Tus respuestas deben ser lo más apegadas a la realidad, reflexiona tus respuestas',
  'Sabemos que es un cuestionario largo, faltan solo algunas preguntas más.',
  '¡Ya estás muy cerca! Sigue así, faltan solo un par de preguntas',
  'La siguiente pregunta podría ser la última, gracias por seguir hasta este punto.',
];
