import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';

export default function InformationItemCard({ Icon, text }) {
  return (
    <div key={text} className="flex items-center py-1">
      <Icon className="h-6 w-6 mr-2 text-tertiary" />
      <Content className="text-content text-gray-500 m-0">{text}</Content>
    </div>
  );
}

InformationItemCard.propTypes = {
  Icon: PropTypes.any.isRequired,
  text: PropTypes.string,
};
