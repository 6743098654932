import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getTokenSelector } from '../state/auth';

export default function RequireAuth({ children }) {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // RRD
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
};
