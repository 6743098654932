import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const accompanimentState = atom({
  key: 'accompaniment',
  default: {
    id: null,
    title: null,
    status: null,
    next_session: null,
    psychologist: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const getIDSelector = selector({
  key: 'getID',
  get: ({ get }) => get(accompanimentState)?.id,
});

export const getPsychologistIDSelector = selector({
  key: 'getPsychologistID',
  get: ({ get }) => get(accompanimentState)?.psychologist?.id,
});
