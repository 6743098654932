import PropTypes from 'prop-types';
import React from 'react';

export default function Content({ className, children }) {
  return <div className={`${className} text-content`}>{children}</div>;
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
