import BackButton from 'components/BackButton';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updateTags } from 'services/patient';
import { getTags } from 'services/tags';
import { authState } from 'state/auth';
import { requestState } from 'state/request';

export default function Tags() {
  // Global state
  const [auth, setAuth] = useRecoilState(authState);
  const [request, setRequest] = useRecoilState(requestState);

  // Local state
  const [selectedTags, setSelectedTags] = useState(auth?.user?.tags);
  const [error, setError] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RQ
  const { data, isLoading } = useQuery(['getTags', auth?.token], () => getTags(auth?.token), {});

  const updatePatientTagsMutation = useMutation(
    (body) => updateTags(auth?.token, auth?.user?.id, body),
    {
      onSuccess: ({ tags }) => {
        toast.success('Se actualizaron las ocupaciónes y padecimientos con exito');

        // UPDATE AUTH STATE
        const newAuth = JSON.parse(JSON.stringify(auth));
        newAuth.user['tags'] = tags;
        setAuth(newAuth);

        // UPDATE REQUEST STATE
        const _request = JSON.parse(JSON.stringify(request));
        _request['hasTags'] = true;
        setRequest(_request);

        navigate('../dover');
      },
      onError: () => toast.error('Algo salió mal.'),
    },
  );

  const handleTag = (id) => {
    let newSelectedTags = new Set(selectedTags);
    if (newSelectedTags.has(id)) {
      newSelectedTags.delete(id);
    } else {
      newSelectedTags.add(id);
    }
    let st = Array.from(newSelectedTags);
    setSelectedTags(st);
  };

  const buildTag = ({ id, text, color }) => {
    const _selectedTags = new Set(selectedTags);
    const selected = _selectedTags.has(id);
    return (
      <div key={id} className="pl-2 pb-2">
        <Tag
          id={id}
          tag={text}
          selected={selected}
          color={color}
          onClickCallback={handleTag}
          // withX={selected}
        />
      </div>
    );
  };

  const buildSection = (question, tags) => (
    <>
      <Subtitle className=" text-gray-500">{question}</Subtitle>
      <div className="mt-6 flex flex-wrap">{tags}</div>
    </>
  );

  const handleSubmit = () => {
    if (!selectedTags.length) {
      setError('Seleccione al menos un tag.');
    } else {
      setError(null);
      updatePatientTagsMutation.mutate({
        tags: selectedTags,
      });
    }
  };

  const occupationTags = data?.data?.occupation_tags.map(buildTag);
  const conditionTags = data?.data?.condition_tags.map(buildTag);

  return (
    <>
      <div className="col-span-1 md:col-start-2 px-4 w-20 mb-4">
        <BackButton onClick={() => navigate(-1)} />
      </div>
      <div className="sm:col-span-1 md:col-span-6 md:col-start-2 p-4">
        {isLoading ? (
          <div className="w-full flex justify-center mt-2">
            <Loader className="h-10 w-10 text-primary" />
          </div>
        ) : (
          <>
            {buildSection('¿Tienes algún perfil religioso? (Opcional)', occupationTags)}
            <br />
            {buildSection('¿Por qué quieres iniciar terapia?', conditionTags)}

            {error && <p className="text-sm font-default font-medium text-red-500 pt-2">{error}</p>}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-4">
              <Button
                disabled={updatePatientTagsMutation.isLoading}
                onClick={handleSubmit}
                boxShadow={true}
                className="col-span-1 md:col-span-2 md:col-start-4 btn-primary"
              >
                {updatePatientTagsMutation.isLoading ? 'Enviando' : 'Guardar'}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
