import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const TAGS_PATH = 'tags/';

export const getTags = async (token) => {
  return await axios.get(`${API_URL}${TAGS_PATH}`, headers(token));
};
