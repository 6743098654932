import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const PSYCHOLOGISTS_PATH = 'psychologists/';

export const getPublicDetail = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${PSYCHOLOGISTS_PATH}${id}/detail/public/`, {
    headers: headers(token),
  });
  return data;
};
