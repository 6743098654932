import PropTypes from 'prop-types';

export default function Title({ children, className }) {
  return <h1 className={`text-title ${className}`}>{children}</h1>;
}

Title.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Title.defaultProps = {};
