import React from 'react';

export default function Frame04() {
  return (
    <svg
      width="138"
      height="106"
      viewBox="0 0 138 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1744_34439)">
        <path
          d="M135.661 91.9609C135.081 87.32 132.191 82.7417 127.743 81.2388C129.496 86.013 129.496 91.2501 127.743 96.0243C127.06 97.8629 126.107 99.839 126.75 101.692C127.151 102.847 128.128 103.728 129.222 104.28C130.317 104.831 131.53 105.096 132.729 105.353L132.965 105.548C134.866 101.279 136.241 96.6018 135.661 91.9609Z"
          fill="#F0F0F0"
        />
        <path
          d="M127.698 81.3257C130.289 84.213 131.899 87.8395 132.299 91.6895C132.401 92.5171 132.37 93.3555 132.208 94.1735C132.026 94.9652 131.633 95.6939 131.072 96.2835C130.56 96.8436 129.971 97.3567 129.603 98.0316C129.421 98.3796 129.314 98.762 129.291 99.1539C129.267 99.5457 129.327 99.938 129.467 100.305C129.791 101.229 130.429 101.982 131.079 102.697C131.799 103.49 132.561 104.304 132.867 105.355C132.906 105.484 133.102 105.419 133.065 105.29C132.54 103.461 130.745 102.42 129.894 100.768C129.497 99.999 129.329 99.1049 129.701 98.2943C130.028 97.5829 130.635 97.0523 131.159 96.4905C131.715 95.9229 132.123 95.2291 132.348 94.4692C132.551 93.6618 132.614 92.8258 132.533 91.9974C132.385 90.1306 131.942 88.2987 131.222 86.5685C130.411 84.5892 129.266 82.7625 127.836 81.1674C127.747 81.0718 127.619 81.2335 127.698 81.3257Z"
          fill="white"
        />
        <path
          d="M132.243 90.4422C132.883 90.2877 133.447 89.9117 133.835 89.3815C134.222 88.8512 134.407 88.2013 134.357 87.5477C134.355 87.5205 134.343 87.495 134.323 87.4767C134.302 87.4584 134.276 87.4489 134.248 87.4503C134.221 87.4517 134.195 87.4639 134.177 87.4841C134.159 87.5043 134.149 87.531 134.15 87.5582C134.198 88.1668 134.026 88.7723 133.664 89.2655C133.302 89.7586 132.775 90.1069 132.178 90.2474C132.049 90.2787 132.116 90.4735 132.243 90.4422Z"
          fill="white"
        />
        <path
          d="M131.369 96.0326C130.214 95.3456 129.36 94.2507 128.979 92.9659C128.941 92.8389 128.745 92.9033 128.782 93.0303C129.181 94.3667 130.072 95.5044 131.277 96.217C131.392 96.2848 131.483 96.1004 131.369 96.0326Z"
          fill="white"
        />
        <path
          d="M130.218 84.787C129.983 84.8981 129.724 84.9494 129.464 84.9364C129.204 84.9233 128.952 84.8463 128.729 84.7122C128.615 84.6426 128.523 84.827 128.636 84.8966C128.883 85.0441 129.162 85.1291 129.449 85.1443C129.736 85.1594 130.022 85.1043 130.283 84.9836C130.309 84.9744 130.33 84.9558 130.342 84.9315C130.355 84.9073 130.357 84.8792 130.35 84.8531C130.341 84.8272 130.322 84.8057 130.298 84.7934C130.273 84.781 130.245 84.7787 130.218 84.787Z"
          fill="white"
        />
        <path
          d="M114.184 89.5273L114.394 89.6647C115.331 90.2771 116.216 90.9647 117.041 91.7208L117.233 91.8948C119.202 93.7353 120.809 95.9243 121.972 98.3499C122.436 99.315 122.824 100.314 123.133 101.338C123.561 102.753 123.913 104.32 124.761 105.477C124.848 105.598 124.943 105.714 125.044 105.824H132.706L132.759 105.8L133.065 105.814C133.052 105.76 133.038 105.704 133.026 105.651C133.014 105.597 133.011 105.588 133.004 105.557C132.997 105.525 132.993 105.515 132.99 105.496V105.477C132.99 105.457 132.981 105.44 132.977 105.423C132.9 105.111 132.821 104.799 132.74 104.489C132.112 102.118 131.28 99.7781 130.117 97.6385C130.082 97.5741 130.047 97.508 130.009 97.4437C129.48 96.482 128.872 95.5651 128.192 94.7023C127.819 94.231 127.421 93.7787 127.002 93.3472C125.916 92.2338 124.661 91.2981 123.282 90.5745C120.534 89.1307 117.352 88.5776 114.415 89.4595L114.184 89.5273Z"
          fill="#F0F0F0"
        />
        <path
          d="M114.202 89.6227C118.018 90.3792 121.498 92.3128 124.147 95.149C124.728 95.7495 125.21 96.4368 125.577 97.1859C125.91 97.9278 126.037 98.7445 125.946 99.5516C125.876 100.307 125.715 101.07 125.831 101.827C125.896 102.213 126.042 102.581 126.26 102.906C126.478 103.232 126.762 103.509 127.095 103.718C127.911 104.262 128.876 104.481 129.829 104.662C130.878 104.864 131.984 105.057 132.865 105.706C132.972 105.786 133.091 105.615 132.984 105.532C131.451 104.389 129.395 104.629 127.717 103.822C126.934 103.446 126.261 102.832 126.067 101.961C125.892 101.199 126.067 100.411 126.138 99.649C126.239 98.8671 126.147 98.0726 125.871 97.3338C125.544 96.5674 125.089 95.8616 124.525 95.2464C123.276 93.8448 121.814 92.6473 120.191 91.6979C118.346 90.6037 116.326 89.8312 114.219 89.414C114.09 89.3879 114.074 89.5879 114.203 89.6192L114.202 89.6227Z"
          fill="white"
        />
        <path
          d="M123.348 94.1801C123.765 93.6728 123.988 93.0346 123.976 92.3792C123.964 91.7239 123.719 91.0941 123.284 90.602C123.196 90.5029 123.037 90.6351 123.125 90.7342C123.531 91.1915 123.759 91.7782 123.769 92.3884C123.778 92.9986 123.568 93.5921 123.177 94.0618C123.093 94.1644 123.263 94.281 123.352 94.1801H123.348Z"
          fill="white"
        />
        <path
          d="M126.032 99.1676C124.693 99.3118 123.349 98.9485 122.268 98.1501C122.161 98.0718 122.043 98.2405 122.149 98.324C123.277 99.1497 124.677 99.5223 126.069 99.3677C126.202 99.3538 126.165 99.1503 126.032 99.1659V99.1676Z"
          fill="white"
        />
        <path
          d="M118.31 90.8771C118.189 91.1069 118.013 91.3034 117.797 91.4488C117.582 91.5942 117.333 91.6841 117.074 91.7103C116.941 91.7224 116.979 91.926 117.111 91.912C117.397 91.8832 117.672 91.7844 117.911 91.6245C118.149 91.4646 118.345 91.2485 118.479 90.9953C118.494 90.9724 118.5 90.9448 118.495 90.918C118.49 90.8913 118.475 90.8673 118.453 90.851C118.431 90.836 118.403 90.8303 118.376 90.8352C118.349 90.8401 118.326 90.8551 118.31 90.8771Z"
          fill="white"
        />
        <path
          d="M76.6937 5.67087C76.5278 5.87677 76.4043 6.11319 76.3302 6.36661C76.2561 6.62003 76.2329 6.8855 76.262 7.14783C76.2911 7.41017 76.3718 7.66424 76.4995 7.89552C76.6273 8.1268 76.7996 8.33077 77.0067 8.49577C77.0992 8.57059 77.1987 8.63641 77.3039 8.69233L76.1221 15.7372L79.531 14.2326L80.1307 7.77215C80.3385 7.3231 80.3738 6.81381 80.2298 6.34063C80.0857 5.86746 79.7724 5.46322 79.3491 5.2044C78.9257 4.94557 78.4217 4.8501 77.9324 4.93606C77.4431 5.02202 77.0024 5.28345 76.6937 5.67087Z"
          fill="#EFD1BA"
        />
        <path d="M107.366 103.13H104.578L103.252 92.4321H107.366V103.13Z" fill="#EFD1BA" />
        <path
          d="M108.076 105.819H99.0884V105.706C99.0884 104.783 99.4567 103.898 100.112 103.246C100.768 102.594 101.657 102.227 102.585 102.227H108.074L108.076 105.819Z"
          fill="#2F2E41"
        />
        <path d="M88.6604 103.13H85.872L84.5469 92.4321H88.6604V103.13Z" fill="#EFD1BA" />
        <path
          d="M89.3703 105.819H80.3828V105.706C80.3828 104.783 80.7512 103.898 81.4069 103.246C82.0626 102.594 82.9519 102.227 83.8792 102.227H89.3686L89.3703 105.819Z"
          fill="#2F2E41"
        />
        <path
          d="M87.4979 53.207L86.449 54.5708C86.449 54.5708 86.3616 55.2074 84.7882 60.3945L83.9141 99.39H89.1587L96.169 68.1874L103.445 100.02L107.99 100.288L107.777 64.1066C107.777 64.1066 108.352 56.6651 106.316 55.2822C104.279 53.8993 87.4979 53.207 87.4979 53.207Z"
          fill="#2F2E41"
        />
        <path
          d="M99.9665 15.8399C100.453 12.8547 98.4162 10.0419 95.416 9.55737C92.4159 9.07281 89.589 11.1 89.102 14.0851C88.615 17.0703 90.6523 19.883 93.6525 20.3676C96.6526 20.8522 99.4795 18.825 99.9665 15.8399Z"
          fill="#EFD1BA"
        />
        <path
          d="M100.067 15.8187L99.9766 15.6447C99.8367 15.3647 98.6724 13.0355 98.5273 12.4423L97.0623 12.6163H93.2477L93.7722 11.8248L92.3736 11.9988C91.8611 12.0602 91.3711 12.244 90.9453 12.5345C90.6848 12.7071 90.4642 12.933 90.2985 13.1973C89.7985 14.0514 89.0328 15.4464 89.0258 15.4586L88.9524 15.5908L88.3178 14.528C88.3178 14.4862 87.5206 10.4002 90.1569 9.01385C90.2933 8.73901 91.4506 6.57858 94.1883 6.7247C94.4892 6.38324 94.9131 6.17329 95.3683 6.14024C95.9924 6.10893 96.6427 6.43595 97.2913 7.11608C98.1654 8.02061 99.3437 9.25911 100.06 10.635L100.258 10.9151C100.539 11.317 100.678 11.8003 100.653 12.2893C100.806 13.0694 100.752 13.8756 100.498 14.6289L100.067 15.8187Z"
          fill="#2F2E41"
        />
        <path
          d="M98.725 21.4688L101.77 23.1352L107.753 24.8033L105.966 40.3786L106.291 55.7294C106.291 55.7294 96.1656 62.6299 86.4561 54.5709V35.2628L87.0312 24.8155C87.0312 24.8155 89.3546 24.158 90.5276 25.2904L92.7461 22.4237L98.725 21.4688Z"
          fill="#E4E4E4"
        />
        <path
          d="M88.372 26.2452L87.0294 24.8102L79.6362 23.977L80.3302 12.6773L76.2989 9.81592L73.3374 25.7234L87.0189 35.2574L88.372 26.2452Z"
          fill="#E4E4E4"
        />
        <path
          d="M103.359 60.7982C103.31 60.5389 103.312 60.2725 103.366 60.0141C103.42 59.7558 103.525 59.5106 103.674 59.2925C103.824 59.0745 104.015 58.8878 104.237 58.7432C104.459 58.5986 104.707 58.499 104.967 58.4499C105.083 58.4274 105.2 58.4152 105.317 58.4134L107.59 51.6416L109.892 54.5604L107.378 60.546C107.345 61.0358 107.135 61.497 106.785 61.8435C106.436 62.19 105.971 62.3981 105.479 62.429C104.987 62.4599 104.5 62.3114 104.109 62.0113C103.719 61.7112 103.452 61.28 103.357 60.7982H103.359Z"
          fill="#EFD1BA"
        />
        <path
          d="M105.505 26.593L107.753 24.8101C107.753 24.8101 110.05 26.0712 110.749 28.3325C111.448 30.5938 112.672 43.4659 112.672 43.4659L109.351 59.9909L105.636 57.4269L107.428 44.3356L104.281 34.7685L105.505 26.593Z"
          fill="#E4E4E4"
        />
        <path d="M77.3302 0H20.6533V13.7036H77.3302V0Z" fill="#E6E6E6" />
        <path d="M22.1411 12.2252H75.8443V1.48047H22.1411V12.2252Z" fill="white" />
        <path
          d="M32.3699 10.9447C34.6417 10.9447 36.4834 9.11226 36.4834 6.85177C36.4834 4.59128 34.6417 2.75879 32.3699 2.75879C30.098 2.75879 28.2563 4.59128 28.2563 6.85177C28.2563 9.11226 30.098 10.9447 32.3699 10.9447Z"
          fill="#5D75F9"
        />
        <path d="M69.7291 5.25H42.207V6.14061H69.7291V5.25Z" fill="#5D75F9" />
        <path d="M69.7291 7.56348H42.207V8.45408H69.7291V7.56348Z" fill="#5D75F9" />
        <path d="M0 20.8286L47.9551 24.3076V36.8318L0 35.9516V20.8286Z" fill="#E6E6E6" />
        <path d="M1.51025 34.3561L46.9094 35.4607V25.5927L1.51025 22.5625V34.3561Z" fill="white" />
        <path
          d="M11.475 24.5807C11.9989 24.6144 12.509 24.7616 12.9697 25.0121C13.4325 25.2633 13.843 25.5998 14.1795 26.0036C14.5289 26.4211 14.8022 26.8962 14.9871 27.4074C15.1843 27.9452 15.2844 28.5135 15.2826 29.086C15.2854 29.6495 15.1853 30.2088 14.9871 30.7367C14.8049 31.2271 14.5308 31.6786 14.1795 32.0674C13.8419 32.4416 13.43 32.7419 12.9697 32.9493C12.5007 33.16 11.9893 33.2605 11.475 33.2433C10.949 33.227 10.4332 33.0952 9.96453 32.8571C9.48947 32.6152 9.06615 32.2838 8.71805 31.8813C8.35021 31.4614 8.06177 30.9788 7.86668 30.4567C7.65929 29.906 7.55329 29.3227 7.55375 28.7346C7.55142 28.1554 7.65754 27.5809 7.86668 27.0403C8.05949 26.5409 8.34738 26.0831 8.71456 25.6922C9.06366 25.3172 9.48922 25.0207 9.96278 24.8225C10.4417 24.6281 10.959 24.5453 11.475 24.5807Z"
          fill="#9EBCF6"
        />
        <path
          d="M20.4067 27.6802L42.5023 28.8265V29.6597L20.4067 28.5917V27.6802Z"
          fill="#9EBCF6"
        />
        <path
          d="M20.4067 30.0479L42.5023 30.9924V31.8326L20.4067 30.9628V30.0479Z"
          fill="#9EBCF6"
        />
        <path d="M0 46.2788L47.9551 45.4004V57.9246L0 61.4035V46.2788Z" fill="#E6E6E6" />
        <path d="M1.51025 59.664L46.9094 56.6338V46.771L1.51025 47.8773V59.664Z" fill="white" />
        <path
          d="M7.55734 53.2972C7.58024 52.7097 7.70934 52.131 7.93845 51.589C8.15443 51.0786 8.46054 50.6109 8.84228 50.2079C9.20611 49.8232 9.64201 49.513 10.1255 49.2947C10.6037 49.0806 11.1238 48.9749 11.6482 48.985C12.163 48.9955 12.6687 49.1222 13.1271 49.3556C13.5777 49.5868 13.9758 49.9077 14.2967 50.2983C14.6322 50.7054 14.8878 51.1715 15.0502 51.6725C15.2256 52.21 15.3031 52.7743 15.2792 53.3389C15.2565 53.91 15.134 54.4727 14.9173 55.0019C14.7121 55.5044 14.4198 55.9672 14.0537 56.3691C13.7015 56.7559 13.2785 57.0723 12.8072 57.3015C12.3358 57.5255 11.8209 57.6443 11.2985 57.6493C10.78 57.6558 10.2669 57.5439 9.79855 57.3223C9.33553 57.1001 8.92412 56.7844 8.59054 56.3952C8.23981 55.9861 7.97153 55.5136 7.80035 55.0036C7.61397 54.4547 7.53158 53.8761 7.55734 53.2972Z"
          fill="#1D2A48"
        />
        <path d="M20.4067 51.2726L42.5023 50.4028V51.236L20.4067 52.1841V51.2726Z" fill="#1D2A48" />
        <path d="M20.4067 53.6403L42.5023 52.5723V53.402L20.4067 54.55V53.6403Z" fill="#1D2A48" />
        <path
          d="M137.825 106H71.2188C71.1724 106 71.1279 105.982 71.0951 105.949C71.0624 105.917 71.0439 105.872 71.0439 105.826C71.0439 105.78 71.0624 105.736 71.0951 105.703C71.1279 105.671 71.1724 105.652 71.2188 105.652H137.825C137.872 105.652 137.916 105.671 137.949 105.703C137.982 105.736 138 105.78 138 105.826C138 105.872 137.982 105.917 137.949 105.949C137.916 105.982 137.872 106 137.825 106Z"
          fill="#CACACA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1744_34439">
          <rect width="138" height="106" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
