import PropTypes from 'prop-types';

export default function Subtitle({ children, className }) {
  return <p className={`text-subtitle ${className}`}>{children}</p>;
}

Subtitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Subtitle.defaultProps = {};
