/* eslint-disable jsx-a11y/anchor-is-valid */
import 'moment-timezone';

import { CalendarIcon } from '@heroicons/react/outline';
import Alert from 'components/Alert';
import BackButton from 'components/BackButton';
import Content from 'components/Content';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listAvailabilities } from 'services/calendar';
import { getTokenSelector } from 'state/auth';
import { requestState } from 'state/request';
import { capitalize } from 'utils/common';
import { localizeDate } from 'utils/datetime';

import Availability from './components/Availability';
import ConfirmModal from './components/ConfirmModal';
import Day from './components/Day';

export default function PsychologistDetail() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [request, setRequestState] = useRecoilState(requestState);

  // Local state
  const [openModal, setOpenModal] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);

  // RRD
  const navigate = useNavigate();
  const { id: psychologistId } = useParams();

  const { data: availabilities, isLoading: isLoadingAvailabilities } = useQuery(
    ['getAvailabilities', token, psychologistId],
    () => listAvailabilities(token, psychologistId),
    {
      refetchOnWindowFocus: false,
      enabled: !!psychologistId,
    },
  );

  // Group availability by day
  const availabilitiesGroupedByDay = availabilities
    ? availabilities.reduce((result, availability) => {
        const startTimeLocal = localizeDate(availability.start_time);
        const endTimeLocal = localizeDate(availability.end_time);
        const parsedAvailability = {
          id: availability.id,
          date: startTimeLocal.format('L'),
          originalStartTime: availability.start_time,
          originalEndTime: availability.end_time,
          parsedDate: startTimeLocal.format('dddd D [de] MMMM [del] YYYY'),
          parsedStartTime: startTimeLocal.format('h:mm a'),
          parsedEndTime: endTimeLocal.format('h:mm a'),
        };

        const day = parsedAvailability.date;
        if (!result[String(day)]) {
          result[String(day)] = [];
        }

        result[String(day)].push(parsedAvailability);
        return result;
      }, {})
    : {};

  const onConfirm = () => {
    setRequestState({
      ...request,
      availability: selectedAvailability,
      hasPayment: false,
    });
    setOpenModal(false);
    navigate('../../contract');
  };

  const buildAvailability = (availability) => (
    <Availability
      key={`availability-${availability.id}`}
      startTime={availability.parsedStartTime}
      endTime={availability.parsedEndTime}
      onClick={() => {
        setSelectedAvailability(availability);
        setOpenModal(true);
      }}
    />
  );

  const buildAgenda = (days) => {
    const agenda = [];
    for (let day in days) {
      const parsedDate = capitalize(days[String(day)][0].parsedDate);
      const availabilities = days[String(day)];

      const dayUI = (
        <Day
          key={`availability-${day}`}
          day={parsedDate}
          availabilities={availabilities}
          buildAvailability={buildAvailability}
        />
      );

      agenda.push(dayUI);
    }

    return <div className="grid grid-cols-1 gap-4">{agenda}</div>;
  };

  return (
    <>
      <div className="col-span-1 md:col-start-2 px-4 w-20 ">
        <BackButton onClick={() => navigate(-1)} />
      </div>

      <div className="sm:col-span-6 md:col-start-2 grid grid-cols-1 p-4">
        <Title className="text-title">Selecciona un horario</Title>
        <br />
        {Object.keys(availabilitiesGroupedByDay).length ? (
          buildAgenda(availabilitiesGroupedByDay)
        ) : (
          <>
            <Alert
              type="warning"
              text={
                <>
                  <Content className="text-content  m-0 ">
                    <span className="font-bold">Alta demanda | </span>Lo sentimos, el Dover
                    seleccionado actualmente no tiene espacio disponible, por favor selecciona otro
                    dover.
                  </Content>
                </>
              }
              showActionIcon={false}
              Icon={CalendarIcon}
            />
          </>
        )}
      </div>

      {isLoadingAvailabilities && (
        <div className="w-full flex justify-center mt-2">
          <Loader className="h-10 w-10 text-primary" />
        </div>
      )}

      <ConfirmModal
        title="Confirmar horario."
        description={
          <>
            {selectedAvailability !== null ? (
              <p className="text-gray-500">
                ¿Está seguro de seleccionar este horario?
                <br />
                <span className="text-tertiary font-bold">
                  {selectedAvailability.date} de {selectedAvailability.parsedStartTime} a{' '}
                  {selectedAvailability.parsedEndTime}
                </span>
                <br />
                <br /> No podrás cambiar la hora de la sesión,{' '}
                <span className="font-bold text-black">
                  {' '}
                  si deseas alguna modificación será posterior al pago.
                </span>
              </p>
            ) : null}
          </>
        }
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={() => onConfirm()}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
}
