import { Transition } from '@headlessui/react';
import { ChatAlt2Icon, HomeIcon, UserIcon } from '@heroicons/react/outline';
import { ArrowRightIcon, ChatIcon, CreditCardIcon, VideoCameraIcon } from '@heroicons/react/solid';
import PriceAlert from 'components/Alerts/PriceAlert';
import SidebarDesktop from 'components/Sidebar/SidebarDesktop';
import SidebarMobile from 'components/Sidebar/SidebarMobile';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module/dist/TagManager';
import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cometChatInit } from 'services/cometChat';
import { getStatus as getUserStatus } from 'services/patient';
import { getStatus as getSessionStatus } from 'services/session';
import { authState, getUserSelector } from 'state/auth';

import Alert from './components/Alert';
import {
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  USER_STATUS_CREATED,
  USER_STATUS_IN_ACCOMPANIMENT,
  USER_STATUS_IN_ACCOMPANIMENT_REQUEST,
  USER_STATUS_NO_ACTIVITIES,
} from './utils/statuses';

const navigation = [
  { name: 'Inicio', href: '/app', icon: HomeIcon, noShow: [] },
  {
    name: 'Chat & Meet',
    href: 'chat',
    icon: ChatIcon,
    noShow: [USER_STATUS_CREATED, USER_STATUS_NO_ACTIVITIES],
  },
  {
    name: 'Historial de Sesiones',
    href: 'sessions',
    icon: ChatAlt2Icon,
    noShow: [USER_STATUS_IN_ACCOMPANIMENT_REQUEST, USER_STATUS_CREATED, USER_STATUS_NO_ACTIVITIES],
  },
  { name: 'Perfil', href: 'profile', icon: UserIcon, noShow: [] },
  {
    name: 'Métodos de pago',
    href: 'payment-methods',
    icon: CreditCardIcon,
    noShow: [USER_STATUS_CREATED, USER_STATUS_NO_ACTIVITIES],
  },
];

if (process.env.REACT_APP_IS_PRODUCTION) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

export default function App() {
  // Global state
  const [auth, setAuth] = useRecoilState(authState);
  const user = useRecoilValue(getUserSelector);

  // Local state
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [fetchSessionInterval, setFetchSessionInterval] = useState(120000);

  // Local vars
  const isUserInAccompaniment = user?.status
    ? user?.status === USER_STATUS_IN_ACCOMPANIMENT
    : false;

  // RRD
  const navigate = useNavigate();

  // const [isLoading, setIsLoading] = useState(true);
  const getTimeRemaining = (start_date) => {
    const now = moment();
    const sessionStartDate = moment.utc(start_date);
    return sessionStartDate.diff(now, 'minutes');
  };

  // Mutation Actions
  const onSuccessPatientStatusRequest = (user) => {
    setAuth({
      token: auth.token,
      user,
    });
  };

  // RQ
  const userStatusQuery = useQuery(['userStatus', auth?.token], () => getUserStatus(auth?.token), {
    enabled: !!auth?.token,
    refetchInterval: 60000,
    onSuccess: onSuccessPatientStatusRequest,
  });

  const onSuccessSessionStatusRequest = (data) => {
    const sessionStartDate = data?.start_date;
    const timeRemaining = getTimeRemaining(sessionStartDate);
    const newSession = Object.assign({}, session);
    newSession['timeRemaining'] = timeRemaining;
    setSession(newSession);
  };

  useQuery(['sessionStatus', session], () => getSessionStatus(auth.token, session.id), {
    onSuccess: onSuccessSessionStatusRequest,
    // refetchOnMount: false,
    enabled: !!auth?.token && !!session?.id,
    refetchInterval: fetchSessionInterval,
  });

  useEffect(() => {
    if (!session?.status) return;
    if (session?.start_date && [SESSION_PAYED, SESSION_IN_PROGRESS].includes(session.status)) {
      const timeRemaining = getTimeRemaining(session.start_date);
      let interval = 60000;
      if (timeRemaining <= 20 && timeRemaining > 3) {
        interval = 30000;
      } else if (timeRemaining <= 3 && timeRemaining >= 0) {
        interval = 12000;
      } else if (timeRemaining < 0 && timeRemaining < -60) {
        interval = 20000;
      }
      if (fetchSessionInterval !== interval) {
        setFetchSessionInterval(interval);
      }
    }

    if (![SESSION_PAYED, SESSION_IN_PROGRESS].includes(session.status)) {
      setFetchSessionInterval(120000);
    }
  }, [fetchSessionInterval, session]);

  useEffect(() => {
    const user = auth.user;
    const initCometChat = async (uid) => {
      await cometChatInit(uid);
      // setIsLoading(false);
    };

    if (user?.cometchat_id) {
      initCometChat(user?.cometchat_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let alertText = '';
  let alertType = 'info';
  let alertCallback = () => null;
  if ([SESSION_PAYED, SESSION_IN_PROGRESS].includes(session?.status)) {
    alertCallback = () => {
      navigate('/app/accompaniment/icchat');
    };
    if (session.timeRemaining > 1) {
      alertText = `Tu sesión comenzara en ${session.timeRemaining} minutos`;
    } else if (session.timeRemaining == 1) {
      alertType = 'session';
      alertText = `Tu sesión comenzara en ${session.timeRemaining} minuto`;
    } else if (session.timeRemaining <= 0) {
      alertType = 'warning';
      alertText = `Tu sesión ya comenzo, dirijete a la sala del chat`;
    }
  }

  return (
    <>
      {isUserInAccompaniment ? null : (
        <PriceAlert
          accompaniment={userStatusQuery.data?.accompaniment}
          alertCallback={alertCallback}
        />
      )}

      <div>
        {isUserInAccompaniment ? (
          <>
            <SidebarDesktop
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              navigation={navigation}
              user={auth?.user}
              setAuth={setAuth}
            />

            {/* Sidebar for Mobile */}
            <SidebarMobile
              setAuth={setAuth}
              setSidebarOpen={setSidebarOpen}
              navigation={navigation}
              userStatus={auth?.user?.status}
            />
          </>
        ) : null}

        <div className={`${isUserInAccompaniment ? 'md:pl-64 ' : ''} flex flex-col flex-1`}>
          <main>
            <Transition
              show={session?.timeRemaining < 15 && session?.timeRemaining > -60}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Alert
                type={alertType}
                text={alertText}
                showActionIcon={true}
                Icon={VideoCameraIcon}
                ActionIcon={ArrowRightIcon}
                actionCallback={alertCallback}
              />
            </Transition>

            <div>
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
