import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const ACCOMPANIMENT_PATH = 'accompaniments/';

export const create = async (token, body) => {
  return await axios.post(`${API_URL}${ACCOMPANIMENT_PATH}`, body, {
    headers: headers(token),
  });
};

export const getDetail = async (token) => {
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENT_PATH}detail/`, {
    headers: headers(token),
  });

  return data;
};

export const getSessions = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENT_PATH}${id}/sessions/pa/`, {
    headers: headers(token),
  });

  return data;
};

export const getTasks = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENT_PATH}${id}/tasks/`, {
    headers: headers(token),
  });

  return data;
};
