import { CalendarIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';
import Content from './Content';
import Subtitle from './Subtitle';
import Tag from './Tag';
import Title from './Title';

export default function PublicProfileCard({
  video,
  name,
  title,
  titleColor,
  degreeCost,
  biography,
  location,
  datetime,
  reason,
  onClick = null,
}) {
  return (
    <div className="w-full" id="contactProfile">
      <iframe
        className="w-full h-80"
        src={video}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <div className="grid gap-4 mt-8">
        <div className="grid grid-cols-1 items-center">
          <div className="col-span-1 md:col-span-2 flex items-center">
            <Title className="text-title pr-2">{name}</Title>
            <Tag selected tag={title} color={titleColor} />
          </div>
          {!onClick ? null : (
            <div className="w-full mt-4">
              <Button
                className="mt-4 md:mt-0 btn-tertiary p-4 hover:shadow-lg"
                onClick={onClick}
                icon={<CalendarIcon className="h-5 w-5 mr-2 text-white" />}
              >
                Seleccionar Horario
              </Button>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div className="col-span-1">
            <div className="mb-8">
              <Subtitle className="text-subtitle text-sm  font-bold">Costo por sesión</Subtitle>
              <Content className="text-content text-gray-500 ">{degreeCost} USD</Content>
            </div>
            <div className="mb-8">
              <Subtitle className="text-subtitle text-sm  font-bold">Ubicación</Subtitle>
              <Content className="text-content text-gray-500 ">{location}</Content>
            </div>

            <div className="mb-8">
              <Subtitle className="text-subtitle text-sm  font-bold">Biografía</Subtitle>
              <Content className="text-content text-gray-500 ">{biography}</Content>
            </div>
          </div>
          <div className="col-span-1">
            <div className="mb-8">
              <Subtitle className="text-subtitle text-sm  font-bold">
                Hora Actual del Psicólogo
              </Subtitle>
              <Content className="text-content text-gray-500 ">{datetime}</Content>
            </div>
            <div className="mb-8">
              <Subtitle className="text-subtitle text-sm  font-bold">
                ¿Por qué estoy en Mindove?
              </Subtitle>
              <Content className="text-content text-gray-500 ">{reason}</Content>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PublicProfileCard.propTypes = {
  video: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  children: PropTypes.any,
  degreeCost: PropTypes.string,
  biography: PropTypes.string,
  location: PropTypes.string,
  datetime: PropTypes.string,
  reason: PropTypes.string,
  onClick: PropTypes.func,
};

PublicProfileCard.defaultProps = {
  titleColor: 'primary',
};
