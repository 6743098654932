import { CheckIcon, CreditCardIcon, PlusIcon } from '@heroicons/react/solid';
import ActionCard from 'components/ActionCard';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import EmptyMessage from 'components/EmptyMessage';
import Header from 'components/Header';
import Loader from 'components/Loader';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { listPaymentMethods, updateDefaultPaymentMethod } from 'services/payments';
import { getTokenSelector } from 'state/auth';

export default function ProfilePaymentMethods() {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // RQ
  const listPaymentMethodsQuery = useQuery(['listPaymentMethods', token], () =>
    listPaymentMethods(token),
  );

  const updateDefaultPaymentMethodMutation = useMutation(
    (data) => updateDefaultPaymentMethod(token, data.id),
    {
      onSuccess: () => {
        toast.success('Método de pago predeterminado, actualizado con exito.');
        listPaymentMethodsQuery.refetch();
      },

      onError: () => toast.error('Algo salío mal, contacte a soporte.'),
    },
  );

  const updateDefaultPaymentMethodCallback = (id) => {
    toast.loading('Actualizando...');
    updateDefaultPaymentMethodMutation.mutate({ id });
  };

  const buildPaymentMethod = ({ id, card_holder_name, card_number, is_default }) => {
    // const variableProps = {};
    let buttonIcon = CheckIcon;
    let buttonText = 'Volver predeterminado';
    let buttonCallback = () => updateDefaultPaymentMethodCallback(id);
    if (is_default) {
      buttonIcon = CreditCardIcon;
      buttonText = 'Predeterminado';
      buttonCallback = () => null;
    }
    return (
      <div key={id} className="mb-4">
        <ActionCard
          firstText={card_holder_name}
          secondText={card_number}
          // withTag={is_default}
          // tagText="Predeterminado"
          // tagColor="primary"
          // tagSelected={is_default}
          FirstButtonIcon={buttonIcon}
          firstButtonText={buttonText}
          firstButtonCallback={buttonCallback}
        />
      </div>
    );
  };

  const addPaymentMethodHandler = () => {
    const paymentMethodHost = new URL(process.env.REACT_APP_PAYMENT_METHOD_HOST);
    paymentMethodHost.searchParams.append('at', token);
    paymentMethodHost.searchParams.append('o', window.location.href);
    paymentMethodHost.searchParams.append('r', window.location.href);
    window.location.replace(paymentMethodHost);
  };

  return (
    <BasicLayout>
      <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
        <Header
          title="Métodos de pago"
          description="Aquí podrá visualizar los métodos de pago guardados (Mindove no guarda ninguna información confidencial)"
        >
          <div className="col-span-1 mt-2 text-center">
            <Button
              className="btn-secondary"
              onClick={() => addPaymentMethodHandler()}
              icon={<PlusIcon className="h-6 w-6" />}
            />
          </div>
        </Header>
        {listPaymentMethodsQuery.isLoading ? (
          <div className="grid grid-cols-3">
            <div className="col-span-1 col-start-2 flex justify-center mt-8">
              <Loader className="h-12 w-12 text-secondary" />
            </div>
          </div>
        ) : null}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {listPaymentMethodsQuery.isSuccess && !listPaymentMethodsQuery.data.length ? (
            <EmptyMessage text="No hay métodos de pago registrados" />
          ) : null}

          {listPaymentMethodsQuery.isSuccess && listPaymentMethodsQuery.data.length
            ? listPaymentMethodsQuery.data.map(buildPaymentMethod)
            : null}
        </div>
      </div>
    </BasicLayout>
  );
}
