import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import Header from 'components/Header';
import InformationItemCard from 'components/InformationItemCard';
import ItemCard from 'components/ItemCard';
import Loader from 'components/Loader';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getSessions as getAccompanimentSessions } from 'services/accompaniment';
import { accompanimentState } from 'state/accompaniment';
import { getTokenSelector } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { sessionStatuses, sessionStatusesTagColor } from 'utils/parsers';

export default function SessionsList() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [accompaniment] = useRecoilState(accompanimentState);
  const [, setSelectedSession] = useRecoilState(selectedSessionState);
  // RRD
  const navigate = useNavigate();

  // RQ
  const getAccompanimentSessionsQuery = useQuery(['getAccompanimentSessions', token], () =>
    getAccompanimentSessions(token, accompaniment.id),
  );

  if (getAccompanimentSessionsQuery.isLoading) {
    return (
      <div className="grid grid-cols-3">
        <div className="col-span-1 col-start-2 flex justify-center mt-8">
          <Loader className="h-12 w-12 text-secondary" />
        </div>
      </div>
    );
  }
  if (getAccompanimentSessionsQuery.isError) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Error...</div>;
  }

  const buildSession = (session, index) => {
    const sessionNumber = `Sesión ${session['number']}`;
    const sessionStatus = sessionStatuses[`${session['status']}`];
    const sessionStatusColor = sessionStatusesTagColor[`${session['status']}`];

    const startDate = moment.utc(session['start_date']).local();
    const endDate = moment.utc(session['end_date']).local();
    const formattedStartDate = startDate.format('DD [de] MMMM [del] YYYY');
    const formattedStartHour = startDate.format('hh:mm a');
    const formattedEndHour = endDate.format('hh:mm a');
    return (
      <button
        key={index}
        className="w-full cursor-pointer rounded-md shadow-md hover:shadow-lg"
        // eslint-disable-next-line no-console
        onClick={() => {
          setSelectedSession({
            id: session.id,
            status: session.status,
          });
          navigate('detail');
        }}
      >
        <ItemCard title={sessionNumber} tag={sessionStatus} tagColor={sessionStatusColor}>
          <InformationItemCard Icon={ClockIcon} text={formattedStartDate} />
          <InformationItemCard
            Icon={CalendarIcon}
            text={`${formattedStartHour} - ${formattedEndHour}`}
          />
        </ItemCard>
      </button>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
      <Header
        title="Sesiones"
        description="Aquí podrás ver todas las sesiones de tu terapia psicoespiritual."
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {getAccompanimentSessionsQuery.data.map(buildSession).reverse()}
      </div>
    </div>
  );
}
