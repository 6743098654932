import { ChevronRightIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';

export default function ActionItem({ icon, title, redirectTo, navigate }) {
  return (
    <button
      onClick={() => navigate(redirectTo)}
      className="w-full flex justify-between items-center bg-gray-50 shadow-sm rounded-md py-2 px-2 my-2 cursor-pointer hover:shadow-md"
    >
      <div className="flex-1 flex justify-start items-center">
        {icon}
        <p className="ml-4 font-default font-normal leading-5 text-md text-gray-500">{title}</p>
      </div>
      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
    </button>
  );
}

ActionItem.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};
