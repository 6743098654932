import { CheckCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function SidebarStepsDesktop({
  profileCompleted,
  hasTags,
  hasPsychologist,
  hasContract,
  hasPayment,
}) {
  let profileStatus = 'current';
  let hasTagsStatus = 'upcoming';
  let hasPsychologistStatus = 'upcoming';
  let hasContractStatus = 'upcoming';
  let hasPaymentStatus = 'upcoming';
  if (profileCompleted) {
    profileStatus = 'complete';
  }

  if (profileCompleted && hasTags) {
    hasTagsStatus = 'complete';
  } else if (profileCompleted && !hasTags) {
    hasTagsStatus = 'current';
  } else {
    hasTagsStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist) {
    hasPsychologistStatus = 'complete';
  } else if (profileCompleted && hasTags && !hasPsychologist) {
    hasPsychologistStatus = 'current';
  } else {
    hasPsychologistStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist && hasContract) {
    hasContractStatus = 'complete';
  } else if (profileCompleted && hasTags && hasPsychologist && !hasContract) {
    hasContractStatus = 'current';
  } else {
    hasContractStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist && hasContract && hasPayment) {
    hasPaymentStatus = 'complete';
  } else if (profileCompleted && hasTags && hasPsychologist && hasContract && !hasPayment) {
    hasPaymentStatus = 'current';
  } else {
    hasPaymentStatus = 'upcoming';
  }

  const steps = [
    {
      id: '01',
      name: 'Completar Perfil',
      href: '#',
      status: profileStatus,
    },
    { id: '02', name: 'Selecciona tus Padecimientos', href: '#', status: hasTagsStatus },
    { id: '03', name: 'Elige a tu Dover', href: '#', status: hasPsychologistStatus },
    { id: '04', name: 'Acepta Términos y Condiciones', href: '#', status: hasContractStatus },
    { id: '05', name: 'Paga', href: '#', status: hasPaymentStatus },
  ];

  return (
    <div className="px-4 py-12 sm:px-6 lg:px-8">
      <nav className="grid justify-center" aria-label="Progress">
        <ol className="space-y-6">
          {steps.map((step) => (
            <li key={step.name}>
              {step.status === 'complete' ? (
                <a href={step.href} className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </a>
              ) : step.status === 'current' ? (
                <a href={step.href} className="flex items-start" aria-current="step">
                  <span
                    className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                    aria-hidden="true"
                  >
                    <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                    <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-indigo-600">{step.name}</span>
                </a>
              ) : (
                <a href={step.href} className="group">
                  <div className="flex items-start">
                    <div
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </p>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

SidebarStepsDesktop.propTypes = {
  profileCompleted: PropTypes.bool.isRequired,
  hasTags: PropTypes.bool.isRequired,
  hasPsychologist: PropTypes.bool.isRequired,
  hasContract: PropTypes.bool.isRequired,
  hasPayment: PropTypes.bool.isRequired,
};

SidebarStepsDesktop.defaultProps = {
  profileCompleted: false,
  hasTags: false,
  hasPsychologist: false,
  hasContract: false,
  hasPayment: false,
};
