import * as Yup from 'yup';

export const LogInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  remember: Yup.bool().default(false),
});

export const TemporaryCodeSchema = Yup.object().shape({
  temporary_code: Yup.string()
    .required('Este campo es requerido')
    .matches(/^\d+$/, 'Este campo solo acepta números')
    .max(6, 'El código debe de ser de 6 digitos.'),
});
