import { XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

export default function Alert({ Icon, text, type, showActionIcon, ActionIcon, actionCallback }) {
  const whiteText = 'text-white';
  const types = {
    error: {
      bgColor: 'bg-red-50',
      iconColor: 'text-red-400',
      textColor: 'text-red-800',
      buttonClasses:
        'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
    },
    warning: {
      bgColor: 'bg-yellow-50',
      iconColor: 'text-yellow-400',
      textColor: 'text-yellow-800',
      buttonClasses:
        'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600',
    },
    info: {
      bgColor: 'bg-primary',
      iconColor: whiteText,
      textColor: whiteText,
      buttonClasses:
        'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600',
    },
    success: {
      bgColor: 'bg-green-50',
      iconColor: 'text-green-400',
      textColor: 'text-green-800',
      buttonClasses:
        'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
    },
    session: {
      bgColor: 'bg-primary',
      iconColor: whiteText,
      textColor: whiteText,
      buttonClasses:
        'bg-secondary text-white hover:bg-primary focus:ring-offset-white focus:ring-white',
    },
  };

  const { bgColor, iconColor, textColor, buttonClasses } = types[`${type}`];

  return (
    <div className={`${bgColor} p-4 shadow-lg rounded-md`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
          {/* <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> */}
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${textColor}`}>{text}</p>
        </div>
        <div className="ml-auto pl-3">
          {showActionIcon ? (
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${buttonClasses}`}
                onClick={actionCallback}
              >
                <span className="sr-only">Dismiss</span>
                <ActionIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  Icon: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
  type: PropTypes.string,
  showActionIcon: PropTypes.bool,
  ActionIcon: PropTypes.any,
  actionCallback: PropTypes.func,
};

Alert.defaultProps = {
  type: 'success',
  showActionIcon: true,
  ActionIcon: XIcon,
};
