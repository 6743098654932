import Avatar from 'assets/icons/Avatar';
import PropTypes from 'prop-types';
import React from 'react';

import Subtitle from './Subtitle';

export default function ProfileCard({ image, title }) {
  return (
    <div
      className="border border-2 rounded-lg cursor-pointer shadow-md hover:border-secondary hover:shadow-lg"
      id="EditProfile"
    >
      <div className="grid grid-cols-1 gap-y-8 justify-center items-center p-4">
        <div className="text-center flex justify-center items-center">
          {image ? (
            <img className=" object-cover  rounded-full h-32 w-32" src={image} alt="" />
          ) : (
            <div className="rounded-full h-32 w-32">
              <Avatar />
            </div>
          )}
        </div>
        <div className="text-center grid">
          <Subtitle className="text-subtitle text-gray-600 font-bold">{title}</Subtitle>
        </div>
      </div>
    </div>
  );
}

ProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  biography: PropTypes.any,
  phone: PropTypes.string,
  image: PropTypes.any,
  navigate: PropTypes.func,
  redirectTo: PropTypes.string,
};

ProfileCard.defaultProps = {
  content: '',
};
