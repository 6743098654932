import PropTypes from 'prop-types';
import React from 'react';

export default function Availability({ startTime, endTime, onClick }) {
  return (
    <button
      onClick={onClick}
      onKeyDown={onClick}
      className="grid w-full p-4 border border-1 hover:ring hover:ring-2 hover:ring-secondary border-gray-300 rounded-md cursor-pointer shadow-md hover:shadow-lg"
    >
      <div className="flex">
        <p className="text-content">
          {startTime}
          {' - '}
          {endTime}
        </p>
      </div>
    </button>
  );
}

Availability.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
