import Loader from 'components/Loader';
import TicketCard from 'components/TicketCard';
import Title from 'components/Title';
import React from 'react';
import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getSessionTickets } from 'services/ticket';
import { getTokenSelector } from 'state/auth';
import { selectedSession } from 'state/session';

export default function SessionTickets() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [session] = useRecoilState(selectedSession);

  const getSessionTicketsQuery = useQuery(
    ['getSessionTickets', token, session?.id],
    () => getSessionTickets(token, { session_id: session?.id }),
    {
      enabled: !!session?.id,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      <Title className="text-lg text-primary leading-6 mt-2 md:mt-0 lg:mt-0">
        Historial de Solicitudes
      </Title>
      <br />
      {getSessionTicketsQuery.isLoading ? (
        <div className="flex justify-center w-full">
          <Loader className="h-12 w-12 text-primary" />
        </div>
      ) : null}
      {getSessionTicketsQuery.isSuccess ? (
        <>
          {getSessionTicketsQuery.data.length ? (
            getSessionTicketsQuery.data.map((ticket, index) => (
              <TicketCard key={index} ticket={ticket} index={index} />
            ))
          ) : (
            <p className="leading-5 text-gray-500 font-md">Esta sesión no tiene tickets</p>
          )}
        </>
      ) : null}
    </div>
  );
}
