import PropTypes from 'prop-types';
import React from 'react';

export default function Button({ children, className, icon, type, onClick, disabled }) {
  // const _reverseIcon = reverseIcon ? 'flex-row-reverse' : 'flex-row';
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`w-full font-medium font-default ${className} disabled:opacity-50 disabled:cursor-not-allowed`}
    >
      <div className={`${icon ? 'flex justify-center items-center' : 'block'}`}>
        {icon}
        {children}
      </div>
    </button>
  );
}

Button.propTypes = {
  reverseIcon: PropTypes.bool,
  children: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  primary: false,
  boxShadow: true,
  reverseIcon: false,
  textCentered: true,
  className: 'text-base',
  type: 'button',
  onClick: () => true,
  disabled: false,
};
