import Input from 'components/Input';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';
import Loader from '../Loader';

export default function FormTemporaryCode({ handleSubmit, onSubmit, register, mutation, error }) {
  const handleChange = ({ target: { id, value } }) => {
    const nextInputKey = Number(id.split('_')[1]) + 1;
    const nextElement = document.getElementById(`digit_${nextInputKey}`);
    if (nextElement !== null && value) {
      nextElement.focus();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <Input
            id={`temporary_code`}
            textPosition={'tracking-[2em] text-center'}
            register={register}
            registerConfig={{}}
            handleChange={handleChange}
            disabled={mutation.isLoading}
            error={error}
          />
        </div>
      </div>
      <div>
        <Button
          disabled={mutation.isLoading}
          type="submit"
          className="btn-tertiary rounded-md"
          icon={mutation.isLoading ? <Loader className="h-6 w-6 text-white" /> : null}
        >
          {mutation.isLoading ? 'Procesando' : 'Ingresar'}
        </Button>
      </div>
    </form>
  );
}

FormTemporaryCode.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  mutation: PropTypes.object.isRequired,
  error: PropTypes.string,
};
