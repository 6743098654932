import PropTypes from 'prop-types';
import React from 'react';

export default function Checkbox({ id, text, checked, onChange, error, required }) {
  return (
    <fieldset className="space-y-5">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            required={required}
            aria-describedby="comments-description"
            name={id}
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            onChange={onChange}
            checked={checked}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className=" leading-5 font-default text-gray-900">
            {text}
          </label>
        </div>
      </div>
      {error && <p className="text-sm font-default font-medium text-red-500 ">{error}</p>}
    </fieldset>
  );
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {};
