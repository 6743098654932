import ActionItem from 'components/ActionItem';
import Content from 'components/Content';
import Loader from 'components/Loader';
import Subtitle from 'components/Subtitle';
import TimezonedNextSessionSchedule from 'components/TimezonedNextSessionSchedule';
import Title from 'components/Title';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDetail as getSessionDetail } from 'services/session';
import { getTokenSelector } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';

import { getActionsByStatus } from './actions';

export default function Detail() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [selectedSession] = useRecoilState(selectedSessionState);

  // RRD
  const navigate = useNavigate();

  // RQ
  const getSessionDetailQuery = useQuery(
    ['sessionDetail', token, selectedSession.id],
    () => getSessionDetail(token, selectedSession.id),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const buildAction = (action, index) => {
    return (
      <ActionItem
        key={index}
        icon={action.icon}
        title={action.title}
        redirectTo={action.redirectTo}
        navigate={navigate}
      />
    );
  };

  const buildTask = (task, index) => {
    return (
      <li key={index} className="py-1 text-gray-500">
        {task.content}
      </li>
    );
  };

  if (getSessionDetailQuery.isLoading) {
    <div className="grid grid-cols-3">
      <div className="col-span-1 col-start-2 flex justify-center mt-8">
        <Loader className="h-12 w-12 text-secondary" />
      </div>
    </div>;
  }

  if (getSessionDetailQuery.isError) {
    return <div className="">Error...</div>;
  }

  const session = getSessionDetailQuery.data;
  if (session) {
    // const sessionNumber = session.number;

    // Dates
    const guessTimezone = moment.tz.guess();
    const startDateUTC = moment.utc(session.start_date);
    const endDateUTC = moment.utc(session.end_date);

    // Status
    const sessionStatus = session.status;
    // const sessionStatusText = sessionStatuses[String(sessionStatus)];
    // const sessionStatusColor = sessionStatusesTagColor[String(sessionStatus)];

    // Actions
    const actions = Array.from(getActionsByStatus(sessionStatus, session.reviewed));

    return (
      <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
        <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-8">
          <div className="col-span-1 md:col-span-5 lg:col-span-7">
            <Title className="text-title">Detalle de la sesión #{session.number}</Title>
            <Content className="text-content text-gray-500">
              Aquí encontrarás toda la información detallada de tu sesión.
            </Content>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
          <TimezonedNextSessionSchedule
            title={`Navegador (${session?.patient?.timezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={session?.patient?.timezone}
          />
          <TimezonedNextSessionSchedule
            title={`Default (${guessTimezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={guessTimezone}
          />
          <TimezonedNextSessionSchedule
            title={`Cather (${session?.psychologist?.timezone})`}
            startDateUTC={startDateUTC}
            endDateUTC={endDateUTC}
            timezone={session?.psychologist?.timezone}
          />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="col-span-2 md:col-span-1">
            {session.tasks.length ? (
              <>
                <Subtitle className="text-subtitle text-secondary mt-2">Tareas</Subtitle>
                <ul className="list-inside list-decimal">{session.tasks.map(buildTask)}</ul>
                <br />
              </>
            ) : null}

            {actions.length ? (
              <>
                <Subtitle className="text-secondary">Acciones</Subtitle>
                {actions.map(buildAction)}
              </>
            ) : null}
          </div>
          <div className="col-span-2">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
