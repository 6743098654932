// Ticket status
export const STATUS_IN_REVIEW = 2;
export const STATUS_APPROVED = 3;
export const STATUS_REJECTED = 4;

// Ticket types
export const TYPE_RESCHEDULE_SESSION = 1;
export const TYPE_CANCEL_SESSION = 2;
export const TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY = 3;
export const TYPE_CANCEL_ACCOMPANIMENT = 4;
export const TYPE_REDUCE_ACCOMPANIMENT = 5;
export const TYPE_EXTEND_ACCOMPANIMENT = 6;
export const TYPE_STOP_ACCOMPANIMENT = 7;

// Ticket requesters
export const REQUESTER_PATIENT = 1;
export const REQUESTER_PSYCHOLOGIST = 2;
export const REQUESTER_HEAD_OF_MENTAL_HEALTH = 3;
export const REQUESTER_ADMIN = 4;
