import {
  AcademicCapIcon,
  CalendarIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';
import { TYPE_EDUCATION, TYPE_EXPERIENCE } from 'utils/constants/experiences';
// eslint-disable-next-line sort-imports
import { formatDate, SIMPLE_FORMAT } from 'utils/formatters';

import InformationItemCard from '../InformationItemCard';
import ItemCard from '../ItemCard';

export default function PublicProfileExperiences({ type, items }) {
  if (type === TYPE_EXPERIENCE) {
    return (
      <>
        {items.map(({ id, title, institute, location, start_date, end_date }, index) => (
          <ItemCard key={`experience-${index}`} id={id} title={title}>
            <InformationItemCard key={0} Icon={AcademicCapIcon} text={institute} />
            <InformationItemCard key={1} Icon={LocationMarkerIcon} text={location} />
            <InformationItemCard
              key={2}
              Icon={CalendarIcon}
              text={`${formatDate(start_date, SIMPLE_FORMAT)} - ${
                end_date ? formatDate(end_date, SIMPLE_FORMAT) : 'Ahora'
              }`}
            />
          </ItemCard>
        ))}
      </>
    );
  }

  if (type === TYPE_EDUCATION) {
    return (
      <>
        {items.map(({ title, charge, company, location, start_date, end_date }, index) => (
          <ItemCard key={`education-${index}`} id={index} title={title} tag={charge}>
            <InformationItemCard key={0} Icon={OfficeBuildingIcon} text={company} />
            <InformationItemCard key={1} Icon={LocationMarkerIcon} text={location} />
            <InformationItemCard
              key={2}
              Icon={CalendarIcon}
              text={`${formatDate(start_date, SIMPLE_FORMAT)} - ${
                end_date ? formatDate(end_date, SIMPLE_FORMAT) : 'Ahora'
              }`}
            />
          </ItemCard>
        ))}
      </>
    );
  }
}

PublicProfileExperiences.propTypes = {
  id: PropTypes.any.isRequired,
  type: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

PublicProfileExperiences.defaultProps = {
  type: 1,
  items: [],
};
