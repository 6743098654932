import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const SESSIONS_PATH = 'sessions/';

export const getDetail = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${SESSIONS_PATH}${id}/`, {
    headers: headers(token),
  });
  return data;
};

export const getStatus = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${SESSIONS_PATH}${id}/status/`, {
    headers: headers(token),
  });
  return data;
};

export const getCancelationStatus = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${SESSIONS_PATH}${id}/cancelation/status`, {
    headers: headers(token),
  });
  return data;
};

export const getRescheduleStatus = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${SESSIONS_PATH}${id}/reschedule/status`, {
    headers: headers(token),
  });
  return data;
};
