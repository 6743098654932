import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Frame01 from '../../../assets/images/Frame01';
import Frame02 from '../../../assets/images/Frame02';
import Frame03 from '../../../assets/images/Frame03';
import Frame04 from '../../../assets/images/Frame04';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Content from '../../../components/Content';
import Title from '../../../components/Title';
import { AuthorizeSchema } from '../../../schemas/autodiagnosis';
import { authorize as authorizeAutodiagnosis } from '../../../services/autodiagnosis';
import { getTokenSelector } from '../../../state/auth';

const data = {
  1: {
    image: Frame01,
    title: 'Cuestionario de Autodiagnóstico',
    description:
      'El objetivo de este cuestionario de autodiagnóstico es el estar enterados lo mejor posible de tus síntomas. De esta manera, estaremos en capacidad de buscar al psicólogo en nuestra plataforma que esté más especializado en atender tu caso.',
  },
  2: {
    image: Frame02,
    title: 'Calidad',
    description:
      'La calidad de nuestro servicio es vital para poder garantizar que tu proceso sea altamente exitoso. Toda la información recabada es estrictamente confidencial. ',
  },
  3: {
    image: Frame03,
    title: 'Confidencialidad',
    description:
      'Este documento solo será leído por el psicólogo que tú mismo has elegido. Recuerda que él analizará la información y te garantizará el que seas atendido. En caso afirmativo te avisará que elijas la fecha de la cita y procedas al pago. ',
  },
  4: {
    image: Frame04,
    title: 'La mejor opción para tu caso',
    description:
      'En el caso que determine que no es la mejor opción para atender tu caso, te remitirá a otro psicólogo para que tengas la cita. No te preocupes, nosotros nos encargamos de buscar al psicólogo que esté mejor preparado para atender tu caso. Sabemos que tienes prisa, pero queremos ayudarte integralmente de la manera más precisa.',
  },
  5: {
    image: null,
    title: 'Cuestionario de Autodiagnóstico',
    description: '',
  },
};

export default function About() {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // Local state
  const [step, setStep] = useState(1);

  const { autodiagnosisID, refetchAutodiagnosisStatus } = useOutletContext();

  // RHF
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      isAuthorized: false,
    },
    resolver: yupResolver(AuthorizeSchema),
  });

  // RQ
  const authorizeAutodiagnosisMutation = useMutation(
    (data) => authorizeAutodiagnosis(token, autodiagnosisID, data),
    {
      onSuccess: () => refetchAutodiagnosisStatus(),
    },
  );

  const stepData = data[`${step}`];

  const onSubmit = () => {
    const isAuthorized = getValues().isAuthorized;
    if (!isAuthorized) {
      setError('isAuthorized', { message: 'Este campo es obligatorio' });
    }
    authorizeAutodiagnosisMutation.mutate({ is_authorized: isAuthorized });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full flex justify-center">
      <div className="max-w-xl">
        <div className="flex flex-col items-center">
          {stepData?.image ? (
            <div>
              <stepData.image />
            </div>
          ) : null}
          <Title className="text-lg md:text-2xl text-primary text-center leading-7 font-medium mt-4">
            {stepData.title}
          </Title>
          <Content className="mt-8 text-center">{stepData?.description}</Content>
          {step === 5 ? (
            <div className="mt-6">
              <form
                id="authorize-form"
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
                className="space-y-6"
              >
                <div className="flex items-center justify-between max-w-lg">
                  <Controller
                    name="isAuthorized"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        id="isAuthorized"
                        text="Autorizo a Mindove la recolección de datos
                        personales así como su uso para fines 
                        diagnósticos."
                        checked={value}
                        onChange={onChange}
                        error={errors?.isAuthorized?.message}
                      />
                    )}
                  />
                </div>
              </form>
            </div>
          ) : null}

          <div className="w-full relative flex justify-center">
            <div className="w-full fixed md:relative bottom-5 md:px-0 md:mt-10">
              <div className="w-full flex flex-col md:flex-row-reverse justify-around">
                <div className="w-full mb-4 md:mb-0 px-4 md:px-0 md:pl-2">
                  <Button
                    disabled={step == 5 && authorizeAutodiagnosisMutation.isLoading}
                    onClick={() => {
                      if (step < 5) setStep(step + 1);
                      if (step === 5) {
                        onSubmit();
                      }
                    }}
                    boxShadow={true}
                    className="text-white bg-primary text-base leading-6 font-medium border border-gray-300 rounded-md"
                  >
                    {step === 5 ? 'Autorizar' : 'Siguiente'}
                  </Button>
                  {/* )} */}
                </div>
                <div className="w-full px-4 md:px-0 md:pr-2">
                  <Button
                    disabled={step === 1}
                    onClick={() => {
                      if (step > 1) setStep(step - 1);
                    }}
                    boxShadow={true}
                    className="text-secondary bg-white text-base leading-6 font-medium border border-gray-300 rounded-md"
                  >
                    Regresar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
