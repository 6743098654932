import axios from 'axios';

import { API_URL } from '../utils/constants';

const AUTH_PATH = 'auth/';

export const signup = async (email, firstName, lastName) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}signup/pat/`, {
    email,
    first_name: firstName,
    last_name: lastName,
  });

  return data;
};

export const getTemporaryCode = async (email) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}login/pat/`, {
    email,
  });

  return data;
};

export const getToken = async (code, email) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}token/`, {
    code,
    email,
  });
  return data;
};
