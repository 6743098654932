import axios from 'axios';
import moment from 'moment';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const CALENDAR_PATH = 'calendar/';

export const listAvailabilities = async (token, psychologistId) => {
  const params = {};
  const tomorrow = moment().add(1, 'days').toDate();
  const inAWeek = moment().add(12, 'days').toDate();
  if (psychologistId) params['calendar__psychologist_id'] = psychologistId;
  params['start_time'] = tomorrow;
  params['end_time'] = inAWeek;
  const { data } = await axios.get(`${API_URL}${CALENDAR_PATH}availabilities/`, {
    headers: headers(token),
    params,
  });

  return data;
};
