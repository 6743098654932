import PropTypes from 'prop-types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function SidebarStepsMobile({
  profileCompleted,
  hasTags,
  hasPsychologist,
  hasContract,
  hasPayment,
}) {
  let profileStatus = 'current';
  let hasTagsStatus = 'upcoming';
  let hasPsychologistStatus = 'upcoming';
  let hasContractStatus = 'upcoming';
  let hasPaymentStatus = 'upcoming';
  if (profileCompleted) {
    profileStatus = 'complete';
  }

  if (profileCompleted && hasTags) {
    hasTagsStatus = 'complete';
  } else if (profileCompleted && !hasTags) {
    hasTagsStatus = 'current';
  } else {
    hasTagsStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist) {
    hasPsychologistStatus = 'complete';
  } else if (profileCompleted && hasTags && !hasPsychologist) {
    hasPsychologistStatus = 'current';
  } else {
    hasPsychologistStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist && hasContract) {
    hasContractStatus = 'complete';
  } else if (profileCompleted && hasTags && hasPsychologist && !hasContract) {
    hasContractStatus = 'current';
  } else {
    hasContractStatus = 'upcoming';
  }

  if (profileCompleted && hasTags && hasPsychologist && hasContract && hasPayment) {
    hasPaymentStatus = 'complete';
  } else if (profileCompleted && hasTags && hasPsychologist && hasContract && !hasPayment) {
    hasPaymentStatus = 'current';
  } else {
    hasPaymentStatus = 'upcoming';
  }

  const steps = [
    {
      id: '01',
      name: 'Completar Perfil',
      href: '#',
      status: profileStatus,
    },
    { id: '02', name: 'Selecciona tus Padecimientos', href: '#', status: hasTagsStatus },
    { id: '03', name: 'Elige a tu Dover', href: '#', status: hasPsychologistStatus },
    { id: '04', name: 'Acepta Términos y Condiciones', href: '#', status: hasContractStatus },
    { id: '05', name: 'Paga', href: '#', status: hasPaymentStatus },
  ];

  return (
    <nav className="flex items-center justify-center mt-8" aria-label="Progress">
      <p className="text-sm font-medium">
        Step {steps.findIndex((step) => step.status === 'current') + 1} of {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === 'complete' ? (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : step.status === 'current' ? (
              <a
                href={step.href}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

SidebarStepsMobile.propTypes = {
  profileCompleted: PropTypes.bool.isRequired,
  hasTags: PropTypes.bool.isRequired,
  hasPsychologist: PropTypes.bool.isRequired,
  hasContract: PropTypes.bool.isRequired,
  hasPayment: PropTypes.bool.isRequired,
};

SidebarStepsMobile.defaultProps = {
  profileCompleted: false,
  hasTags: false,
  hasPsychologist: false,
  hasContract: false,
  hasPayment: false,
};
