import * as Yup from 'yup';

const requiredFieldMessage = 'Este campo es requerido.';

export const ActionRescheduleSchema = Yup.object().shape({
  description: Yup.string().required(requiredFieldMessage),
  options: Yup.array()
    .of(
      Yup.object({
        timestamp: Yup.date().required(requiredFieldMessage),
      }),
    )
    .min(2, 'Seleccione al menos 2 horarios')
    .required(requiredFieldMessage),
});

export const ActionCancelSessionSchema = Yup.object().shape({
  confirm: Yup.string()
    .matches(/^(Apruebo cancelar la cita)/, 'El texto debe ser "Apruebo cancelar la cita"')
    .required(requiredFieldMessage),
  reason: Yup.string().required(requiredFieldMessage),
});
