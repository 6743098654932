import {
  ACCOMPANIMENT_ACCEPTED,
  ACCOMPANIMENT_CANCELED_BY_ADMIN,
  ACCOMPANIMENT_CANCELED_BY_PATIENT,
  ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_CANCELED_NO_PAYMENT,
  ACCOMPANIMENT_CREATED,
  ACCOMPANIMENT_FINALIZED,
  ACCOMPANIMENT_IN_PROGRESS,
  ACCOMPANIMENT_STOPPED_BY_PATIENT,
  ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_STOPPED_NO_PAYMENT,
} from 'utils/statuses';

const inProgress = { text: 'En progreso', color: 'bg-primary' };
const inPause = { text: 'En pausa', color: 'bg-yellow-500' };
const canceled = { text: 'Cancelado', color: 'bg-red-500' };
const finalized = { text: 'Finalizado', color: 'bg-secondary' };

export const ACCOMPANIMENT_STATUSES = {
  [ACCOMPANIMENT_CREATED]: inProgress,
  [ACCOMPANIMENT_ACCEPTED]: inProgress,
  [ACCOMPANIMENT_IN_PROGRESS]: inProgress,
  [ACCOMPANIMENT_STOPPED_NO_PAYMENT]: inPause,
  [ACCOMPANIMENT_STOPPED_BY_PATIENT]: inPause,
  [ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST]: inPause,
  [ACCOMPANIMENT_CANCELED_NO_PAYMENT]: canceled,
  [ACCOMPANIMENT_CANCELED_BY_PATIENT]: canceled,
  [ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST]: canceled,
  [ACCOMPANIMENT_CANCELED_BY_ADMIN]: canceled,
  [ACCOMPANIMENT_FINALIZED]: finalized,
};
