import PropTypes from 'prop-types';
import React from 'react';

export default function Select({
  id,
  error,
  disabled,
  register,
  registerConfig,
  textLabel,
  selectClassName,
  selectOptions,
  withDefaultOption,
  required,
}) {
  /**
   * className property is mostly to change label text color
   */

  /**
   * withIndex property is to know if we are going to render the
   * option with their map INDEX or their ID instead.
   *
   */

  /**
   * renderSelectOptions function is to render all the selected options we will need,
   * this receives an array, this can change in the future depending on the backend
   */

  let renderSelectOptions = () => {
    const options = selectOptions.map((option, index) => {
      return (
        <option value={option.id} key={index}>
          {option.name}
        </option>
      );
    });

    if (withDefaultOption) {
      return [
        <option key={'default'} value="">
          Selecciona una opción
        </option>,
        ...options,
      ];
    }

    return options;
  };

  // LabelClasses and borderClasses
  let borderClasses = 'border-gray-300 focus:ring-secondary focus:border-secondary';
  let labelClasses = 'text-gray-700';
  if (error) {
    labelClasses = 'text-pink-700';
    borderClasses = 'border-pink-300 focus:ring-pink-500 focus:border-pink-500 text-pink-500';
  }
  if (disabled) {
    labelClasses = 'text-gray-300';
    borderClasses = 'border-gray-300 text-gray-300';
  }

  return (
    <div>
      <div className="flex items-center">
        <label
          htmlFor="location"
          className={`block font-bold text-sm font-default ${labelClasses}`}
        >
          {textLabel}
        </label>
        {required ? <p className="ml-1 text-pink-500">*</p> : null}
      </div>

      <select
        disabled={disabled}
        id={id}
        {...register(id, registerConfig)}
        className={`mt-1 block font-default w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${selectClassName} ${borderClasses}`}
      >
        {renderSelectOptions()}
      </select>
      {error && <p className="text-sm font-default font-medium text-red-500 pt-2">{error}</p>}
    </div>
  );
}

Select.propTypes = {
  textLabel: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.string,
  selectClassName: PropTypes.string,
  register: PropTypes.func,
  registerConfig: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  selectOptions: PropTypes.array,
  withDefaultOption: PropTypes.bool,
  required: PropTypes.bool,
};

Select.defaultProps = {
  className: 'text-gray-700 text-sm',
  withDefaultOption: false,
  required: false,
};
