/**
 * Login the current user in cometchat.
 *
 * @param {number} uid User UUID linked to cometchat.
 * @return {Promise} Promise.
 */
export const cometchatLogin = async (uid) => {
  // We login
  const { CometChat } = await import('@cometchat-pro/chat');
  let promise = new Promise((resolve, reject) => {
    const COMETCHAT_AUTH_KEY = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
    CometChat.login(uid, COMETCHAT_AUTH_KEY).then(
      () => {
        resolve(true);
      },
      (error) => {
        reject(error);
      },
    );
  });

  return await promise;
};

export const cometChatInit = async (uid) => {
  const { CometChat } = await import('@cometchat-pro/chat');
  let promise = new Promise((resolve, reject) => {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(process.env.REACT_APP_COMETCHAT_APP_REGION)
      .build();
    const COMETCHAT_APP_ID = process.env.REACT_APP_COMETCHAT_APP_ID;
    CometChat.init(COMETCHAT_APP_ID, appSetting).then(
      async () => {
        await cometchatLogin(uid);
        resolve(true);
      },
      (error) => {
        reject(error);
      },
    );
  });

  return await promise;
};
