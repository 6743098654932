import { ArrowLeftIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';

export default function BackButton({ disabled, onClick }) {
  return (
    <Button
      className="btn-back md:col-start-2 px-2 py-1"
      onClick={onClick}
      disabled={disabled}
      icon={<ArrowLeftIcon className="w-6 h-6" />}
    ></Button>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

BackButton.defaultProps = {
  onClick: () => true,
  disabled: false,
};
