import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const COUNTRIES_PATH = 'countries/';
const STATES_PATH = 'states/';
const TIMEZONES_PATH = 'timezones/';

export const listCountries = async (token) => {
  const { data } = await axios.get(`${API_URL}${COUNTRIES_PATH}`, {
    headers: headers(token),
  });

  return data;
};

export const listStates = async (token, country) => {
  const { data } = await axios.get(`${API_URL}${STATES_PATH}`, {
    headers: headers(token),
    params: {
      country,
    },
  });

  return data;
};

export const listTimezones = async (token, country) => {
  const { data } = await axios.get(`${API_URL}${TIMEZONES_PATH}`, {
    headers: headers(token),
    params: {
      country,
    },
  });

  return data;
};
