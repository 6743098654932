import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const ANSWERS_PATH = 'answers/';

export const createOrUpdate = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${ANSWERS_PATH}`, body, {
    headers: headers(token),
  });
  return data;
};
