import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';
import Tag from './Tag';

export default function ActionCard({
  firstText,
  secondText,
  tagText,
  tagColor,
  tagSelected,
  FirstButtonIcon,
  firstButtonText,
  firstButtonCallback,
  SecondButtonIcon,
  secondButtonText,
  secondButtonCallback,
  MainIcon,
  MainImage,
  mediaClassName,
  withTag,
}) {
  return (
    <div
      className="border rounded-md shadow-sm hover:shadow-md grid cursor-pointer my-2"
      id="PaymentMethodItem"
    >
      <div className="grid p-4">
        <div>
          <div className="flex items-center">
            <p className="font-default font-normal leading-5 text-sm m-0 mr-2">{firstText}</p>
            {withTag ? <Tag selected={tagSelected} tag={tagText} color={tagColor} /> : null}
          </div>
          <div className="font-default font-normal my-1 text-sm text-gray-500 object-cover">
            {secondText}
          </div>
        </div>
        <div className={`${mediaClassName ? mediaClassName : 'bg-secondary '} rounded-full`}>
          {MainIcon && <MainIcon className="h-6 w-6 text-white" />}
          {MainImage && (
            <img alt="icon" src={MainImage} className="h-8 w-8 rounded-full object-cover" />
          )}
        </div>
      </div>

      <div
        className={`w-full border-t grid ${secondButtonText ? 'grid-cols-2' : 'grid-cols-1'} p-2`}
      >
        <Button
          onClick={firstButtonCallback}
          className="text-primary"
          boxShadow={false}
          icon={<FirstButtonIcon className="h-6 w-6 text-primary mr-2" />}
        >
          {firstButtonText}
        </Button>

        {secondButtonText ? (
          <Button
            onClick={secondButtonCallback}
            className="text-primary"
            boxShadow={false}
            icon={<SecondButtonIcon className="h-6 w-6 text-primary mr-2" />}
          >
            {secondButtonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

ActionCard.propTypes = {
  firstText: PropTypes.string.isRequired,
  secondText: PropTypes.any,
  tagText: PropTypes.string,
  tagColor: PropTypes.string,
  tagSelected: PropTypes.bool,
  FirstButtonIcon: PropTypes.any.isRequired,
  firstButtonText: PropTypes.string,
  firstButtonCallback: PropTypes.func,
  SecondButtonIcon: PropTypes.any,
  secondButtonText: PropTypes.string,
  secondButtonCallback: PropTypes.func,
  MainIcon: PropTypes.any,
  MainImage: PropTypes.string,
  mediaClassName: PropTypes.string,
  withTag: PropTypes.bool,
};

ActionCard.defaultProps = {
  firstButtonText: 'Editar',
  tagSelected: false,
  withTag: true,
};
