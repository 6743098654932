import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { USER_STATUS_CREATED, USER_STATUS_IN_ACCOMPANIMENT_REQUEST } from 'utils/statuses';

import { getStatusSelector } from '../state/auth';

export default function RouteManager({ children }) {
  // Global state
  const status = useRecoilValue(getStatusSelector);

  // RRD
  const navigate = useNavigate();
  const location = useLocation();

  // Local vars
  const isRequestPath = location.pathname.includes('/app/request');

  useEffect(() => {
    if (
      !isRequestPath &&
      [USER_STATUS_CREATED, USER_STATUS_IN_ACCOMPANIMENT_REQUEST].includes(status)
    ) {
      return navigate('/app/request');
    }
  }, [isRequestPath, navigate, status]);

  return children;
}

RouteManager.propTypes = {
  children: PropTypes.any.isRequired,
};
