import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from './statuses';

const periodicity = {
  '*/15': 'Quincenal',
  '*': 'Semanal',
};

const days = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miercoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sabado',
  7: 'Domingo',
};

export const buildUserFriendlyCrontTab = (crontab) => {
  const crontabArray = crontab.split(' ');
  const every = periodicity[`${crontabArray[2]}`];
  const _days = crontabArray[4]
    .split(',')
    .map((day) => days[`${day}`])
    .join(', ');
  const hour = crontabArray[1];
  const minutes = crontabArray[0];
  return {
    every,
    days: _days,
    hour,
    minutes,
  };
};

export const sessionStatuses = {
  [SESSION_CREATED]: 'Creada',
  [SESSION_SCHEDULED]: 'Agendada',
  [SESSION_PAYED]: 'Pagada',
  [SESSION_UNPAYED]: 'Sin pago',
  [SESSION_CANCELED]: 'Cancelada',
  [SESSION_IN_PROGRESS]: 'En progreso',
  [SESSION_TIME_COMPLETED]: 'Termino la sesión',
  [SESSION_FINALIZED]: 'Finalizada',
};

export const sessionStatusesTagColor = {
  [SESSION_CREATED]: 'primary',
  [SESSION_SCHEDULED]: 'secondary',
  [SESSION_PAYED]: 'tertiary',
  [SESSION_UNPAYED]: 'fourth',
  [SESSION_CANCELED]: 'fifth',
  [SESSION_IN_PROGRESS]: 'sixth',
  [SESSION_TIME_COMPLETED]: 'seventh',
  [SESSION_FINALIZED]: 'eigthth',
};
