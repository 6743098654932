import PropTypes from 'prop-types';
import React from 'react';

import Tag from './Tag';
import Title from './Title';

export default function ItemCard({ id, title, tag, tagColor, children }) {
  // iconSpanItem is a dynamic objects with array where we send only 2 items, the icon and the span
  return (
    <div key={id} className="w-full rounded-md px-4 py-4 shadow-md">
      <div className="w-full flex justify-between items-baseline">
        <Title className="text-title text-lg text-gray-500">{title}</Title>
        {tag ? <Tag tag={tag} color={tagColor} selected /> : null}
      </div>
      <div className="flex flex-col mt-2">{children}</div>
    </div>
  );
}

ItemCard.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  tag: PropTypes.string,
  tagColor: PropTypes.string,
};

ItemCard.defaultProps = {
  tagColor: 'primary',
};
