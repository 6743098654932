import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Button from './Button';
import ProfilePicture from './ProfilePicture';

export default function ProfilePictureInput({ id, setValue, disabled, photoURL }) {
  const [imgSrc, setImgSrc] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRemove = () => {
    setImgSrc(null);
    setValue(null);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setValue(fileUploaded);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = function () {
      setImgSrc(reader.result);
    };
  };

  return (
    <div>
      <label htmlFor="ProfilePicture" className="block text-sm font-medium text-gray-700">
        Foto
      </label>
      <div className="mt-4 grid sm:grid-cols-3 gap-y-4 gap-x-4">
        <div className="col-span-1">
          <span className="rounded-full overflow-hidden  flex justify-center">
            <ProfilePicture imgSrc={imgSrc ? imgSrc : photoURL} />
          </span>
        </div>
        <div className="self-center sm:col-span-2 grid gap-y-4 md:px-4">
          <Button
            disabled={disabled}
            type="button"
            onClick={handleClick}
            className="md:w-28 btn-secondary"
          >
            {imgSrc || photoURL ? 'Cambiar' : 'Cargar'}
          </Button>
          {imgSrc || photoURL ? (
            <Button
              disabled={disabled}
              type="button"
              onClick={handleRemove}
              className="md:w-28 btn-tertiary"
            >
              Eliminar
            </Button>
          ) : null}
        </div>
        <input
          disabled={disabled}
          id={id}
          ref={hiddenFileInput}
          type="file"
          className="hidden"
          multiple={false}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

ProfilePictureInput.propTypes = {
  id: PropTypes.any,
  setValue: PropTypes.any,
  disabled: PropTypes.bool,
  photoURL: PropTypes.any,
};
