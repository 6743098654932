import BackButton from 'components/BackButton';
import Content from 'components/Content';
import ListLoader from 'components/ListLoader';
import PsychologistItem from 'components/PsychologistItem';
import TransitionOpacity from 'components/TransitionOpacity';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getPsychologistsByTags } from 'services/patient';
import { getTokenSelector } from 'state/auth';
import { requestState } from 'state/request';
import { shuffle } from 'utils/common';
import { PSYCHOLOGIST_DEGREES } from 'utils/constants';

export default function Psychologists() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [request, setRequest] = useRecoilState(requestState);

  // Local state
  const [shufflePsychologists, setShufflePsychologists] = useState([]);

  // RRD
  const navigate = useNavigate();

  // RQ
  const { isSuccess: isSuccessPsychologists, isLoading } = useQuery(
    ['getPsychologistsByPatientTags', token],
    () => getPsychologistsByTags(token),
    {
      refetchOnWindowFocus: false,
      onSuccess: (psychologists) => {
        const _shufflePsychologists = shuffle(psychologists);
        setShufflePsychologists(_shufflePsychologists);
      },
    },
  );

  const getPsychologistDetail = (id, first_name, last_name, degree, photo_URL) => {
    setRequest({
      ...request,
      psychologist: {
        id,
        firstName: first_name,
        lastName: last_name,
        degree,
        photoURL: photo_URL,
      },
    });
    navigate(String(id));
  };

  return (
    <>
      <TransitionOpacity as={Fragment} show={isSuccessPsychologists}>
        <div className="col-span-1 md:col-start-2 px-4 w-20 mb-4">
          <BackButton onClick={() => navigate(-1)} />
        </div>
        <div className="sm:col-span-1 md:col-span-6 md:col-start-2 p-4">
          <div>
            <Content className="text-gray-500">
              A continuación te presentamos una lista con nuestras recomendaciones según la
              descripción de tu problema.
            </Content>
          </div>
          <br />
          <ul className="grid grid-cols-1 gap-4">
            {shufflePsychologists.map(
              ({
                id,
                first_name,
                last_name,
                address: {
                  state: { name, country },
                },
                degree,
                photo_URL,
              }) => (
                <PsychologistItem
                  key={`psychologist-${id}`}
                  image={photo_URL}
                  name={`${first_name} ${last_name}`}
                  location={`${name}, ${country?.name}`}
                  psychologistType={PSYCHOLOGIST_DEGREES[`${degree}`].degree}
                  psychologistTypeColor={PSYCHOLOGIST_DEGREES[`${degree}`].color}
                  amount={`${PSYCHOLOGIST_DEGREES[String(degree)].sessionCost} USD / Hora`}
                  onClickCallback={() =>
                    getPsychologistDetail(id, first_name, last_name, degree, photo_URL)
                  }
                />
              ),
            )}
          </ul>
        </div>
      </TransitionOpacity>

      {isLoading && (
        <>
          <div>
            <p className="text-sm leading-5 font-default font-normal text-gray-500">
              Estamos buscando a los mejores Dovers para ayudarte.
            </p>
          </div>
          <br />
          <ListLoader />
          <br />
          <ListLoader />
        </>
      )}
    </>
  );
}
