import Content from 'components/Content';
import ImageCard from 'components/ImageCard';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export default function Stopped({ pausedAt }) {
  const datetime = moment.utc(pausedAt).local().format('DD [de] MMMM [del] YYYY [a las] hh:mm a');

  return (
    <ImageCard
      title="¡Estamos en pausa!"
      className="bg-primary bg-yellow-500"
      callback={() => true}
    >
      <Content className="text-content text-white">
        Tu acompañamiento se encuentra en pausa hasta el <br />{' '}
        <span className="font-bold text-lg">{datetime}</span>
      </Content>
    </ImageCard>
  );
}

Stopped.propTypes = {
  pausedAt: PropTypes.string,
};
