import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const AUTODIAGNOSES_PATH = 'autodiagnoses/';

export const create = async (token) => {
  const { data } = await axios.post(
    `${API_URL}${AUTODIAGNOSES_PATH}`,
    {},
    {
      headers: headers(token),
    },
  );
  return data;
};

export const checkStatus = async (token) => {
  const { data } = await axios.get(`${API_URL}${AUTODIAGNOSES_PATH}status/`, {
    headers: headers(token),
  });
  return data;
};

export const authorize = async (token, id, body) => {
  const { data } = await axios.patch(`${API_URL}${AUTODIAGNOSES_PATH}${id}/authorize/`, body, {
    headers: headers(token),
  });
  return data;
};
