import { XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';

import Content from './Content';

// TODO: Inprove this
let colorStatus = {
  primary: 'bg-tag_status_1 text-tag_status_1',
  secondary: 'bg-tag_status_2 text-tag_status_2',
  tertiary: 'bg-tag_status_3 text-tag_status_3',
  fourth: 'bg-tag_status_4 text-tag_status_4',
  fifth: 'bg-tag_status_5 text-tag_status_5',
  sixth: 'bg-tag_status_6 text-tag_status_6',
  seventh: 'bg-tag_status_7 text-tag_status_7',
  eighth: 'bg-tag_status_8 text-tag_status_8',
  nineth: 'bg-tag_status_9 text-tag_status_9',
  tenth: 'bg-tag_status_10 text-tag_status_10',
};

export default function Tag({ id, tag, selected, color, withX, withDot, onClickCallback }) {
  return (
    <div
      aria-hidden="true"
      role={'button'}
      className="cursor-pointer "
      onClick={() => onClickCallback(id)}
    >
      <Content
        className={`text-sm px-4 py-2 rounded-md  transition transform ease-in-out delay-150 ${
          selected ? colorStatus[String(color)] + ' font-bold' : 'text-content'
        }`}
      >
        {withDot && (
          <svg
            className="-ml-0.5 mr-1.5 h-3 w-3 text-indigo-400"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
        )}
        {tag}
        {withX && (
          <XIcon className={`h-3 w-3 ml-1 mt-px text-white ${colorStatus[String(color)]}`} />
        )}
      </Content>
    </div>
  );
}

Tag.propTypes = {
  id: PropTypes.number,
  tag: PropTypes.string,
  selected: PropTypes.bool,
  color: PropTypes.string,
  withX: PropTypes.bool,
  withDot: PropTypes.bool,
  onClickCallback: PropTypes.func,
};

Tag.defaultProps = {
  selected: false,
  withX: false,
  withDot: false,
  onClickCallback: () => null,
};
