import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import React from 'react';

export default function TransitionOpacity({ show, children }) {
  return (
    <Transition
      show={show}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 "
      leaveTo="opacity-0  "
    >
      {children}
    </Transition>
  );
}

TransitionOpacity.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};
